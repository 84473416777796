import React, { Component } from "react";
import "../../_core/helpers/String"; // .decodeHTML prototype
import Link from "../../_core/components/Link";
import SiteModifier from "../../_core/components/SiteModifier";
import Html from "../../_core/components/Html";
import Icon from "../../_core/components/Icon";
import Data from "../../_core/models/Data";

class CategoryNav extends Component {
  render() {
    let modifier = this.props.modifier || "default";

    return (
      <Data>
        {({ categories }) => {
          return (
            <ul className={`List List--categories List--${modifier}`}>
              <li>
                <Link to={window.location.pathname + "#/all"}>All</Link>
              </li>
              {categories.map((c, cix) => (
                <li key={`c_${cix}`}>
                  <Link to={`#/filter/${c.slug}/`} navlink>
                    {c.name.decodeHTML()}
                  </Link>
                </li>
              ))}
            </ul>
          );
        }}
      </Data>
    );
  }
}

export default CategoryNav;
