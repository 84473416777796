import React, { Component } from "react";
//import Swiper from 'swiper';
import { withRouter } from "react-router-dom";

//import 'swiper/css/swiper.css';
import "./swiper.css";

// Polyfill for Element.closest()
if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.msMatchesSelector ||
    Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function (s) {
    var el = this;

    do {
      if (el.matches(s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

const Swiper = window.Swiper;

class SwiperComponent extends Component {
  busy = false;
  ref = false;
  api = false;
  defaultParams = {
    // Optional parameters
    direction: "horizontal",
    loop: true,

    // If we need pagination
    pagination: {
      el: ".swiper-pagination",
    },

    // Navigation arrows
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },

    // And if we need scrollbar
    scrollbar: {
      el: ".swiper-scrollbar",
    },

    on: {
      init: function () {
        //console.log('swiper initialized');
      },
      click: (event) => {
        if (this.busy) {
          event.stopPropagation();
          event.preventDefault();
          return false;
        }

        this.busy = true;
        setTimeout(() => {
          this.busy = false;
        }, 400);

        let element = event.target;
        let url = element.getAttribute("data-href");
        let slide = element.closest("[data-swiper-slide-index]");

        if (slide) {
          let active = slide.className.match("swiper-slide-active");

          var children = Array.prototype.slice.call(
            slide.parentElement.children
          );

          if (!active) {
            let ix = children.indexOf(slide);
            this.api.slideTo(ix);

            setTimeout(() => {
              this.api.loopFix();
            }, 600);
          } else {
            //specific element that was clicked i.e.: p or img tag
            if (typeof url != "undefined" && url !== null && url != "") {
              event.stopPropagation();
              event.preventDefault();
              this.props.history.push(url);
            }
          }
        } else {
          // No slide
        }
      },
    },
  };

  componentDidMount = () => {};

  componentWillUnmount = () => {
    this.busy = false;
    this.api.destroy();
  };

  setRef = (el) => {
    this.ref = el;
    let propsParams = this.props.params || {};
    let params = { ...this.defaultParams, ...propsParams };

    this.api = new Swiper(this.ref, params);
  };

  render() {
    return (
      <div className="swiper-container" ref={this.setRef}>
        <div className="swiper-wrapper">
          {this.props.children.map((child, ix) => (
            <div className="swiper-slide" key={`swiper_slide_${ix}`}>
              {child}
            </div>
          ))}
        </div>

        <div className="swiper-pagination"></div>

        <div className="swiper-button-prev"></div>
        <div className="swiper-button-next"></div>

        <div className="swiper-scrollbar"></div>
      </div>
    );
  }
}

export default withRouter(SwiperComponent);
