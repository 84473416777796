import GoogleAnalytics from "react-ga";
import theme from "./theme.js";
import {
  WP_pagesTransformer,
  WP_mediaTransformer,
  WP_structuresTransformer,
} from "./transformers/wordpress.js";

const IS_PRODUCTION =
  window.location.hostname == "kolrestaurants.com" ||
  window.location.hostname.match("mjmk");
const d = new Date();
let CACHEBUSTER = !IS_PRODUCTION
  ? "?nc=" + d.getFullYear() + d.getMonth() + d.getDate() + d.getHours()
  : "?nc=" + d.getTime();

CACHEBUSTER += "v.0.19"; // Version tag for cache flushing

// //jks.content.webcdn.network/
// const HOST = IS_PRODUCTION
//   ? "/"
//   : "//proxy.olson.work/?p=https://mjmk.kol.uk.plesk-server.com/";
const HOST = IS_PRODUCTION ? "/" : "/";

const settings = {
  SITENAME: "KOL",
  BRAND: "KOL Restaurant",
  STRAPLINE: "Mexican soul. British ingredients.",
  GA_ID: false, // Analytics ID
  STYLES: theme,
  MANIFEST: [
    {
      name: "frontpage",
      src: HOST + "data.json" + CACHEBUSTER,
      type: "json",
      subset: "frontpage",
    },
    {
      name: "entries",
      src: HOST + "entries.json" + CACHEBUSTER,
      type: "json",
      transformer: WP_pagesTransformer,
    },
    {
      name: "assets",
      src: HOST + "assets.json" + CACHEBUSTER,
      type: "json",
      transformer: WP_mediaTransformer,
    },
    {
      name: "structures",
      src: HOST + "data.json" + CACHEBUSTER,
      type: "json",
      subset: "structures",
      transformer: WP_structuresTransformer,
    },
    {
      name: "categories",
      src: HOST + "data.json" + CACHEBUSTER,
      subset: "categories",
      type: "json",
    },
  ],
  GOOGLE_MAPS_API_KEY: "XXX", // JKS Maps ID
};

export default settings;
