import React, { Component } from "react";

class Logo extends Component {
  render() {
    let svg = `
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 455.8 152" style="enable-background:new 0 0 455.8 152;" xml:space="preserve">
<metadata><?xpacket begin="﻿" id="W5M0MpCehiHzreSzNTczkc9d"?>
<x:xmpmeta xmlns:x="adobe:ns:meta/" x:xmptk="Adobe XMP Core 5.6-c143 79.161356, 2017/09/07-01:11:22        ">
   <rdf:RDF xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#">
      <rdf:Description rdf:about=""/>
   </rdf:RDF>
</x:xmpmeta>                        
<?xpacket end="w"?></metadata>
<style type="text/css">
  .st1{clip-path:url(#SVGID_2_);}
</style>
<polygon class="st0" points="342.2,2.2 342.2,149.8 455.8,149.8 455.8,131.2 374.5,137.9 379.8,2.2 "/>
<g>
  <defs>
    <rect id="SVGID_1_" width="455.8" height="152"/>
  </defs>
  <clipPath id="SVGID_2_">
    <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
  </clipPath>
  <path class="st1" d="M200.9,119c-15.1-15.7-29.5-58.9-4.3-95.9c6.3-9.2,15.2-13.2,15.2-13.2s11.8,0.2,23.4,5.1
    c23.3,9.8,40.2,34.6,37.9,74.2c-2.2,37.5-24.3,51.4-24.3,51.4S223,142,200.9,119 M227.2,0c-48.6,0-82.3,32.6-82.3,75.8
    c0,43.6,33.7,76.2,82.3,76.2c48.4,0,82.3-32.6,82.3-76.2C309.5,32.6,275.6,0,227.2,0"/>
</g>
<rect y="2.2" class="st0" width="37.6" height="147.7"/>
<polygon class="st0" points="100.5,2.2 38,75.8 94,149.8 140.5,149.8 140.5,145.5 58.9,67.1 129.6,4.1 129.6,2.2 "/>
</svg>

    `;
    return <div dangerouslySetInnerHTML={{ __html: svg }} />;
  }
}

export default Logo;
