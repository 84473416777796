import React, { Component } from "react";
import "../../_core/helpers/String"; // .decodeHTML prototype
import Link from "../../_core/components/Link";
import SiteModifier from "../../_core/components/SiteModifier";
import Html from "../../_core/components/Html";
import Icon from "../../_core/components/Icon";
import Data from "../../_core/models/Data";
import { Michelin, Top50 } from "./svgs";

class IconNav extends Component {
  render() {
    let modifier = this.props.modifier || "default";

    return (
      <Data>
        {({ structures }) => {
          if (!structures.hasOwnProperty(this.props.label)) return null;

          let nav = structures[this.props.label];

          return (
            <ul className={`List List--icons List--${modifier}`}>
              <li>
                <Top50 />
              </li>
              <li>
                <Michelin />
              </li>
              {nav.map((v, vix) => (
                <li key={`k_${vix}`}>
                  <Link to={`${v.uri}`}>
                    <Icon type={v.slug} glyph />
                  </Link>
                </li>
              ))}
            </ul>
          );
        }}
      </Data>
    );
  }
}

export default IconNav;
