import React, { Component } from "react";
import Swiper from "../Swiper";

class Slider extends Component {
  render() {
    const params = {
      slidesPerView: "auto",
      spaceBetween: 30,
      loop: true,
      grabCursor: true,
      centeredSlides: true,
      pagination: {
        el: ".swiper-pagination",
        type: "fraction",
      },
    };

    return (
      <Swiper params={{ ...params }}>
        {this.props.items.map((item, ix) => (
          <div key={`slide_${ix}`} className="swiper-slide">
            {item}
          </div>
        ))}
      </Swiper>
    );
  }
}

export default Slider;
