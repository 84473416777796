import React, { Component } from "react";

class Each extends Component {
  render() {
    const items = this.props.items;

    if (!items) return null;

    const _this = this;
    let num = items.length - 1;

    let response = items.map((item, ix) => {
      let loop = {
        first: ix == 0,
        last: ix == num,
      };
      let rendered = _this.props.render(item, ix, loop);
      return { ...rendered, key: ix };
    });

    return response;
  }
}

export default Each;
