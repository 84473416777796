import React, { Component } from "react";

class If extends Component {
  render() {
    const result = this.props.condition;

    if (!result) return null;

    return this.props.children;
  }
}

export default If;
