import React, { Component } from "react";
import Entry from "../../models/Entry";
import hashLink from "../../helpers/hashLink";
import { withRouter } from "react-router-dom";

let HAS_HISTORY = false;

class Link extends Component {
  ref = null;
  href = "";
  constructor(props) {
    super(props);
    // Listen for history changes so that we can set a flag to confirm there is history for back buttons
    this.props.history.listen((location, action) => {
      if (action == "PUSH") HAS_HISTORY = true;
    });
  }
  navigate = (event) => {
    if (this.props.back) {
      event.preventDefault();

      console.log("HAS_HISTORY", HAS_HISTORY);

      if (!HAS_HISTORY) {
        let backTo = this.props.fallback || window.location.pathname + "#back";
        this.props.history.push(backTo);
      } else {
        this.props.history.goBack();
      }
      return;
    }

    // Internal link?
    let href = this.ref.getAttribute("href");
    let hasExtension = href.indexOf(".") != -1;
    let internalLink = href.indexOf("/") == 0 || href.indexOf("#") == 0;
    if (internalLink && !hasExtension) {
      event.preventDefault();
      window.site.deModify("dark");
      this.href = href;

      if (this.props.before) {
        this.props.before(this.loadHref, this.ref);
      } else {
        this.loadHref();
      }
    }
  };

  isExternal = (href) => {
    if (typeof href != "string") return false;

    let curAddressParts = window.location.href.split("/");
    let curAddress = curAddressParts[0] + "//" + curAddressParts[2];

    // Remove current address from href for comparison
    href = href.replace(curAddress, "");

    let hasExtension = href.indexOf(".") != -1;

    // Link starts with a slash or same URL as current
    let internalLink = href.indexOf("/") == 0;

    let isEmail = href.indexOf("@") != -1;
    let isTel = href.indexOf("tel:") != -1;
    let isHash = href.indexOf("#") == 0;

    if ((internalLink && !hasExtension) || isEmail || isTel || isHash) {
      return false;
    } else {
      return true;
    }
  };

  loadHref = () => {
    this.props.history.push(this.href);
  };

  setRef = (el) => {
    this.ref = el;

    if (this.props.setRef) {
      this.props.setRef(el);
    }
  };
  render() {
    // Return children if no link
    if (
      !this.props.to &&
      !this.props.allowEmpty &&
      !this.props.back &&
      !this.props.hashLink
    )
      return this.props.children;

    let { className } = this.props;
    let prefix = this.props.prefix || "";

    let to = this.props.to;
    if (!to) to = "#";

    // Hashlink will stitch this uri into the existing hash fragment
    if (this.props.hashLink) {
      let toggle = this.props.hasOwnProperty("toggle")
        ? this.props.toggle
        : true;
      let hashKey = !this.props.hashKey ? to : this.props.hashKey;
      let hashRemoveEmpty = this.props.hasOwnProperty("hashRemoveEmpty")
        ? this.props.hashRemoveEmpty
        : false;
      to = this.props.hashKey
        ? hashLink({
            key: hashKey,
            value: this.props.hashVal,
            toggle: toggle,
            removeEmpty: hashRemoveEmpty,
          })
        : hashLink({
            key: hashKey,
            toggle: toggle,
            removeEmpty: hashRemoveEmpty,
          });
    }

    if (this.props.navlink) {
      let matchUri = this.props.matchUri ? this.props.matchUri : this.props.to;
      let exact = this.props.hasOwnProperty("exact")
        ? this.props.exact
        : this.props.to == "/";
      let matchAgainst = this.props.hasOwnProperty("matchAgainst")
        ? this.props.matchAgainst
        : window.location.pathname + window.location.hash;
      let isActive = exact
        ? matchAgainst == matchUri
        : matchAgainst.match(matchUri);

      className += " ";
      className += isActive ? "is-active" : "is-inactive";
    }

    let autoTarget = null;

    if (
      !this.props.back &&
      (!this.props.hasOwnProperty("autotarget") || this.props.autotarget)
    ) {
      autoTarget = this.isExternal(to) ? "_blank" : null;
    }

    let rel = autoTarget == "_blank" ? "noopener noreferrer" : null;

    let _this = this;

    let linkId = [];
    if (Array.isArray(this.props.to)) linkId = this.props.to;
    if (Number.isInteger(this.props.to)) linkId = [this.props.to];

    if (Array.isArray(this.props.to) || linkId.length) {
      return (
        <Entry
          id={linkId}
          render={(e) => (
            <a
              href={prefix + e.uri}
              ref={_this.setRef}
              target={this.props.target || autoTarget}
              rel={rel}
              onClick={this.navigate}
              className={className}
            >
              {this.props.children}
            </a>
          )}
        />
      );
    } else {
      return (
        <a
          href={prefix + to}
          ref={_this.setRef}
          target={this.props.target || autoTarget}
          rel={rel}
          onClick={this.navigate}
          className={className}
        >
          {this.props.children}
        </a>
      );
    }
  }
}

export default withRouter(Link);
