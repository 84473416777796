import React, { Component } from "react";
import Data from "./Data";
import { withStore } from "../../utils/Store";

/*

e.g. Set a value for some global JSX from inside a template 

<SetData name={'foo'} key={entry.id}>
	{
		() => (
			<div>{entry.title}</div>
		)
	}
</SetData>

*/

class SetData extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.checkSet();
  }

  componentDidUpdate(nextProps) {
    this.checkSet(nextProps);
  }

  componentWillUnmount() {}

  checkSet = (nextProps) => {
    let store = false;

    if (!nextProps) {
      store = true;
    } else {
      let nextKey = nextProps.key || null;
      let key = this.props.key || null;

      store = nextKey != key;
    }

    if (store) {
      let value = this.props.value || this.props.children;
      this.props.store.store(this.props.name, this.props.children);
      this.props.store.publish();
    }
  };

  /*

		RENDER

	*/

  render() {
    let set = {};
    set[this.props.name] = this.props.children;
    return null;
    return <Data set={set} />;
  }
}

export default withStore(SetData);
