let cookieAPI = {};

cookieAPI.getCookies = function () {
  var pairs = document.cookie.split(";");
  var cookies = {};
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    let key = (pair[0] + "").trim();
    if (key != "") {
      cookies[key] = unescape(pair[1]);
    }
  }
  return cookies;
};

cookieAPI.getCookie = function (name) {
  var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return v ? v[2] : null;
};
cookieAPI.setCookie = function (name, value, days) {
  var d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
  document.cookie =
    name +
    "=" +
    value +
    ";path=/;expires=" +
    d.toGMTString() +
    `;domain=${window.location.hostname};`;
};
cookieAPI.deleteCookie = function (name) {
  cookieAPI.setCookie(name, "", -1);
};

cookieAPI.clearCookies = function () {
  var cookies = document.cookie.split("; ");
  for (var c = 0; c < cookies.length; c++) {
    var d = window.location.hostname.split(".");
    while (d.length > 0) {
      var cookieBase =
        encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
        "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
        d.join(".") +
        " ;path=";
      var p = window.location.pathname.split("/");
      document.cookie = cookieBase + "/";
      while (p.length > 0) {
        document.cookie = cookieBase + p.join("/");
        p.pop();
      }
      d.shift();
    }
  }
};

export default cookieAPI;
