import React, { Component } from "react";
import Content from "../_core/components/Content";
import CookieManager from "../_core/components/Cookie/CookieManager";

// Project
import Blocks from "../components/Blocks";

class Default extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  /*

		RENDER

	*/

  render() {
    let entry = this.props.entry;
    let blocks = entry.blocks || [];

    let content = [...blocks];

    if (entry.slug == "cookies") return <CookieManager />;

    return (
      <React.Fragment>
        <div
          className={`Template Template--default Template--type-${entry.type}`}
        >
          <div className="Template-content">
            <div className="Template-head">
              <Content>
                <h1>{entry.title.decodeHTML()}</h1>
              </Content>
            </div>
            <div className="Template-body">
              <Content html={entry.content} />
            </div>
          </div>
          <div className="Template-blocks">
            <Blocks blocks={content} entry={entry} modifiers={["debug"]} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Default;
