import React from "react";

function isModernBrowser() {
  // From https://stackoverflow.com/questions/20539330/modern-browsers-detection
  // Probably feature detection is the right thing to do here...
  let isModern = typeof Intl != "undefined";
  return isModern;
}

export const Modern = (props) => {
  if (isModernBrowser()) {
    return props.children();
  } else {
    return null;
  }
};

export const Old = (props) => {
  if (!isModernBrowser()) {
    return props.children();
  } else {
    return null;
  }
};
