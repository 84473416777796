import React, { Component } from "react";

import Link from "../../_core/components/Link";
import Content from "../../_core/components/Content";
import SiteModifier from "../../_core/components/SiteModifier";
import Data from "../../_core/models/Data";
import Default from "../../templates/default";
import OpacityScreen from "../OpacityScreen";

import { withRouter } from "react-router-dom";

let HAS_HISTORY = false;

class Article extends Component {
  articleId = 0;
  isClosed = true;
  $scrollPane = false;
  backTo = "/";

  constructor(props) {
    super(props);
    // Listen for history changes so that we can set a flag to confirm there is history for back buttons
    this.props.history.listen((location, action) => {
      if (action == "PUSH") HAS_HISTORY = true;
    });
  }

  close = () => {
    if (!this.isClosed) {
      this.isClosed = true;

      if (!HAS_HISTORY) {
        this.props.history.push(this.backTo);
      } else {
        this.props.history.goBack();
      }

      setTimeout(() => {
        this.$scrollPane.scrollX = 0;
        this.$scrollPane.scrollY = 0;
      }, 1000);
    }
  };

  render() {
    return (
      <Data>
        {({ ARTICLE, SHOW_ARTICLE, POSTS, MAIN }) => {
          if (SHOW_ARTICLE) {
            // Reset when we show a new project
            this.isClosed = false;
            this.backTo = MAIN.uri;
          }

          return (
            <div>
              {(() => {
                if (SHOW_ARTICLE) {
                  return <SiteModifier modifier="article" auto />;
                } else {
                  return <SiteModifier demodifier="article" auto />;
                }
              })()}

              {(() => {
                if (ARTICLE) {
                  let blocks = ARTICLE.blocks || [];
                  let postNumber = POSTS.indexOf(ARTICLE);

                  postNumber += 1;

                  let num = postNumber;
                  // Add leading zeros
                  if (postNumber < 100) num = `0${num}`;
                  if (postNumber < 10) num = `0${num}`;

                  return (
                    <React.Fragment>
                      <div
                        data-close
                        className="Site-article-close"
                        style={{ backgroundColor: ARTICLE.article_background }}
                      >
                        <Link data-close back fallback={this.backTo}>
                          Close
                        </Link>
                      </div>
                      <div
                        data-scroll-pane
                        key={ARTICLE.id}
                        ref={(el) => (this.$scrollPane = el)}
                      >
                        <div
                          className="Site-article-content"
                          style={{
                            backgroundColor: ARTICLE.article_background,
                          }}
                        >
                          <Default entry={ARTICLE} />
                        </div>
                        <OpacityScreen
                          start={window.innerHeight}
                          reverse
                          onComplete={() => this.close()}
                        />
                      </div>
                    </React.Fragment>
                  );
                }
              })()}
            </div>
          );
        }}
      </Data>
    );
  }
}

export default withRouter(Article);
