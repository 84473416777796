export const Michelin = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 916 1000"
    xmlSpace="preserve"
    height="27.5px"
    width="30px"
  >
    <path d="M624 219v-49c0-49.3-15.7-90-47-122S506 0 458 0c-52 0-92.7 16.3-122 49s-44 76.3-44 131v15c0 2.7.3 5 1 7l1 17c-49.3-35.3-93.7-53-133-53-30.7 0-60 10.3-88 31-26.7 19.3-46.3 44.7-59 76-9.3 22-14 44-14 66 0 67.3 38.7 118.7 116 154l15 7C43.7 540 0 594 0 662c0 46 16 86.3 48 121s70 52 114 52c40 0 79.7-14.3 119-43l13-10c-1.3 13.3-2 29.3-2 48 0 50 15.7 90.8 47 122.5s71 47.5 119 47.5c52.7 0 93.5-16.5 122.5-49.5S624 874.3 624 821v-39c49.3 35.3 93.3 53 132 53 43.3 0 80.8-17.5 112.5-52.5S916 707.3 916 662c0-68-38.7-119.7-116-155l-15-7c87.3-40 131-93.7 131-161 0-43.3-15.7-83-47-119s-69.2-54-113.5-54S672 180 638 208l-14 11zm-84 169c77.3-112.7 149-169 215-169 27.3 0 52.2 12.3 74.5 37 22.3 24.7 33.5 52.3 33.5 83 0 82.7-89.3 131.7-268 147v29c88.7 7.3 155.5 23.3 200.5 48s67.5 57.7 67.5 99c0 30-10.8 57.5-32.5 82.5S783.7 782 755 782c-67.3 0-139-56.7-215-170l-26 15c38 77.3 57 142.7 57 196 0 82.7-37.7 124-113 124-36 0-64-11-84-33-19.3-23.3-29-52.7-29-88 0-53.3 19-119.7 57-199l-26-15c-76 113.3-147.7 170-215 170-27.3 0-52.2-11.8-74.5-35.5S53 694.7 53 662c0-82.7 89-131.7 267-147v-29C142 471.3 53 422.3 53 339c0-29.3 10.7-56.7 32-82 21.3-25.3 46.7-38 76-38 66 0 137.7 56.3 215 169l26-14c-38-78-57-144.7-57-200 0-35.3 9.7-64.7 29-88 20-22 48-33 84-33 75.3 0 113 41 113 123 0 54-19 120-57 198l26 14z" />
  </svg>
);

export const Top50 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 206.41 278.75"
    height="30px"
    width="30px"
  >
    <path
      className="cls-1"
      d="M199.93 264.48H199v-3.78h-1.42v-.8h3.72v.8h-1.37Zm2.79 0h-.87v-4.58h1.45l.83 3.16.83-3.16h1.45v4.58h-.88v-3.65l-1 3.65h-.84l-1-3.65Z"
      id="Trademark"
    />
    <g id="_50_Best" data-name="50 Best">
      <g id="_50_BEST_RESTAURANTS" data-name="50 BEST RESTAURANTS">
        <g id="RESTAURANTS">
          <path
            className="cls-1"
            d="M181.83 270.11c.16 1.3.47 2 1.68 4 1.74 2.91 3.4 4.24 5.32 4.24a2.45 2.45 0 0 0 2.29-.89 3.69 3.69 0 0 0 .78-2.26 2.81 2.81 0 0 0-1-2.14c-.79-.68-.79-.68-5.43-2.23a5.35 5.35 0 0 1-4-5c0-3.2 3.14-5.86 6.89-5.86a9.4 9.4 0 0 1 3.09.65 5.88 5.88 0 0 0 1.6.27 1.2 1.2 0 0 0 1.34-1h.32v7h-.32a8.33 8.33 0 0 0-1.61-4.18 5.1 5.1 0 0 0-4.1-2.36 2.25 2.25 0 0 0-1.9.81 3.2 3.2 0 0 0-.73 2 2.71 2.71 0 0 0 1.09 2.09 3.21 3.21 0 0 0 1.19.65l3.44 1c3 .83 4.52 2.71 4.52 5.46a5.55 5.55 0 0 1-2.09 4.55 7.93 7.93 0 0 1-5.11 1.88c-1.38 0-1.44 0-5.26-1.25a2.7 2.7 0 0 0-.85-.14 1.09 1.09 0 0 0-1.22.93h-.29v-8.18Zm-18.41-9.93h17.07v7.65h-.24a7.43 7.43 0 0 0-1.3-4.13c-.84-1.41-2.83-3.06-3.67-3.06h-.6v16c0 .85.54 1.28 1.63 1.28h.87v.46h-10.81v-.46h.93c1.16 0 1.79-.55 1.79-1.55v-15.73h-.52a6 6 0 0 0-2.28 1.38 9.14 9.14 0 0 0-2.15 3.15 4.43 4.43 0 0 0-.46 2.25v.41h-.26Zm-4.1 12.62v-8.12a9.66 9.66 0 0 0-.52-2.68 2.14 2.14 0 0 0-2-1.31h-.7v-.48h6.29v.48h-.64a2.06 2.06 0 0 0-1.6 1.14 5 5 0 0 0-.49 2.61v13.93h-4.29l-8-15.35v10.19c0 2 .08 2.47.52 3.28a2.09 2.09 0 0 0 2.12 1.36h.62v.41H144v-.46h.57c1.52 0 2.43-1.87 2.43-5.05v-10.18a2 2 0 0 0-.72-1.45 2.13 2.13 0 0 0-1.47-.43v-.48h7.93Z"
          />
          <path
            className="cls-1"
            d="M124.28 277.85c1.85-.08 3-1.41 4.32-5.15l4-12.66h4.07l5.41 16.1c.51 1.57.67 1.71 1.71 1.71h.78v.44h-10.51v-.44h1.12c.75 0 1.13-.24 1.13-.76a2.61 2.61 0 0 0-.17-.95l-1.7-5.38h-4.73a13.13 13.13 0 0 0-1.09 4.19 2.89 2.89 0 0 0 1.12 2.5 3.31 3.31 0 0 0 1.93.4v.44h-7.39Zm10.05-7.47-2.25-7.22-2.37 7.22Zm-19.07-10.2c2.78 0 4.08.24 5.5 1a4 4 0 0 1 2.19 3.36 3.62 3.62 0 0 1-1.06 2.5c-.78.77-1.54 1.14-3.55 1.82 2.36.63 3.28 1.12 3.91 2.07.48.72.62 1.39.62 3.17v1.9c0 .65.16 1 .51 1 .68 0 1.2-1.47 1.2-3.42v-1.09h.36v1.33c0 1.93-.35 3.09-1.19 3.78a4 4 0 0 1-2.63.92 4.67 4.67 0 0 1-3.21-1.25 4 4 0 0 1-.92-3c0-.22 0-.76.06-1.2v-1.68c0-1.49-.52-2.22-1.61-2.22h-1.57v7.49c0 .7.35 1.17.89 1.17h.93v.52h-9.26v-.52h.39c1.13 0 1.64-.38 1.64-1.25v-15c0-.65-.29-.87-1.16-.87h-.84v-.55Zm-1.35 8.46h1.22c1.93 0 2.45-.76 2.45-3.66 0-1.87-.11-2.75-.47-3.39a1.68 1.68 0 0 0-1.55-.9h-1.65Z"
          />
          <path
            className="cls-1"
            d="M96.11 274.89a2.53 2.53 0 0 0 .68 1.87 4.64 4.64 0 0 0 2.8.79 4.24 4.24 0 0 0 3.28-1.14c.6-.68.63-.82.63-2.61v-9.42a4.06 4.06 0 0 0-1.25-3 3.23 3.23 0 0 0-2.2-.78.49.49 0 0 0-.14 0v-.43h7v.43h-.54a1.75 1.75 0 0 0-1.22.38 4.47 4.47 0 0 0-1.3 3.36v10.26c0 1.39-.6 2.29-2.12 3.13a7.34 7.34 0 0 1-3.86.92c-2.71 0-5.24-1-6.4-2.47a3.39 3.39 0 0 1-.63-2.36l-.05-11.95a1.17 1.17 0 0 0-1.17-1.25h-.73v-.43h9.21v.43h-.65a1.33 1.33 0 0 0-1.34 1.49Zm-24.84 2.96c1.85-.08 3-1.41 4.31-5.15l4-12.66h4.07l5.4 16.1c.52 1.57.68 1.71 1.71 1.71h.79v.44H81v-.44h1.12c.76 0 1.13-.24 1.13-.76a3.09 3.09 0 0 0-.15-.95l-1.71-5.38H76.7a12.87 12.87 0 0 0-1.09 4.24 2.89 2.89 0 0 0 1.12 2.5 3.25 3.25 0 0 0 1.92.4v.44h-7.38Zm10-7.47-2.25-7.22-2.36 7.22Z"
          />
          <path
            className="cls-1"
            d="M57.83 260.18h17.08v7.65h-.25a7.27 7.27 0 0 0-1.3-4.13c-.84-1.41-2.82-3.06-3.66-3.06h-.6v16c0 .85.54 1.28 1.63 1.28h.87v.46H60.79v-.46h.92c1.17 0 1.8-.55 1.8-1.55v-15.73H63a6 6 0 0 0-2.29 1.36 9.12 9.12 0 0 0-2.14 3.15 4.34 4.34 0 0 0-.47 2.25v.41h-.27Zm-14.38 9.93c.16 1.3.46 2 1.68 4 1.74 2.91 3.39 4.24 5.32 4.24a2.44 2.44 0 0 0 2.28-.89 3.7 3.7 0 0 0 .79-2.26 2.84 2.84 0 0 0-1-2.14c-.79-.68-.79-.68-5.43-2.23a5.37 5.37 0 0 1-4-5c0-3.2 3.16-5.86 6.91-5.86a9.44 9.44 0 0 1 3.09.65 5.88 5.88 0 0 0 1.6.27 1.19 1.19 0 0 0 1.33-1h.33v7h-.33a8.15 8.15 0 0 0-1.61-4.18 5.07 5.07 0 0 0-4.09-2.36 2.23 2.23 0 0 0-1.9.81 3.2 3.2 0 0 0-.73 2 2.7 2.7 0 0 0 1.08 2.09 3.24 3.24 0 0 0 1.2.65l3.44 1c3 .83 4.51 2.71 4.51 5.46a5.55 5.55 0 0 1-2.09 4.55 7.93 7.93 0 0 1-5.11 1.88c-1.38 0-1.44 0-5.26-1.25a2.64 2.64 0 0 0-.84-.14 1.08 1.08 0 0 0-1.22.93h-.31v-8.18ZM40.68 267h-.32a5.71 5.71 0 0 0-1.29-4 6.24 6.24 0 0 0-5.07-2.48h-.82v7.77h.52a3.73 3.73 0 0 0 2.79-1.68 3.49 3.49 0 0 0 .85-2.47v-.25h.35v9.7h-.35v-.27a4.21 4.21 0 0 0-.9-2.72 4.08 4.08 0 0 0-2.74-1.76h-.52v8.28c0 .59.43.81 1.57.81a4.57 4.57 0 0 0 4.16-1.88 16 16 0 0 0 2-3.66 5.83 5.83 0 0 0 .4-2.43h.36v8.33H25v-.39h.9c1.52 0 2-.4 2-1.65v-14.64c0-.78-.6-1.09-2.12-1.09H25v-.4h15.68Z"
          />
          <path
            className="cls-1"
            d="M16 260.18c2.77 0 4.08.24 5.48 1a4 4 0 0 1 2.2 3.36 3.62 3.62 0 0 1-1.06 2.5 8.14 8.14 0 0 1-3.62 1.82c2.36.63 3.28 1.12 3.91 2.07.49.72.62 1.39.62 3.17v1.9c0 .65.17 1 .52 1 .68 0 1.19-1.47 1.19-3.42v-1.09h.35v1.33c0 1.93-.35 3.09-1.19 3.78a4 4 0 0 1-2.64.92 4.7 4.7 0 0 1-3.2-1.25 4 4 0 0 1-.92-3c0-.22 0-.76.06-1.2 0-.62.05-1.41.05-1.68 0-1.49-.52-2.22-1.6-2.22h-1.54v7.49c0 .7.34 1.17.89 1.17h.92v.52H7.16v-.52h.38c1.14 0 1.66-.38 1.66-1.25v-15c0-.65-.3-.87-1.16-.87h-.85v-.55Zm-1.35 8.46h1.21c1.93 0 2.45-.76 2.45-3.66a7.22 7.22 0 0 0-.46-3.39 1.71 1.71 0 0 0-1.56-.9h-1.68Z"
          />
        </g>
        <g id="BEST">
          <path
            className="cls-1"
            d="M147.7 201.43h49.69v22.28h-.71c-.32-5.14-1-7.27-3.8-12-2.44-4.11-8.21-8.93-10.67-8.93h-1.73v46.45c0 2.45 1.58 3.72 4.74 3.72h2.53v1.34H156.3v-1.34h2.7c3.4 0 5.22-1.58 5.22-4.5v-45.68h-1.5c-1.11 0-4.42 2.06-6.64 4a26.37 26.37 0 0 0-6.24 9.17 12.67 12.67 0 0 0-1.35 6.55v1.19h-.78Zm-41.35 28.91c.48 3.79 1.35 5.93 4.9 11.77 5 8.46 9.87 12.33 15.48 12.33 3.48 0 5.14-.64 6.64-2.61a10.52 10.52 0 0 0 2.29-6.56 8.23 8.23 0 0 0-2.77-6.24c-2.28-2-2.28-2-15.79-6.48-7.19-2.44-11.62-8-11.62-14.53 0-9.33 9.17-17.06 20.07-17.06 2.3 0 3.08.16 9 1.89a18.17 18.17 0 0 0 4.67.79 3.48 3.48 0 0 0 3.87-3h.91v20.31h-.95a24.2 24.2 0 0 0-4.66-12.17c-3.16-4.42-7.43-6.87-11.93-6.87a6.53 6.53 0 0 0-5.53 2.37 9.27 9.27 0 0 0-2.14 5.77 7.9 7.9 0 0 0 3.17 6.08 10.19 10.19 0 0 0 3.47 1.9l10 2.84c8.61 2.45 13.11 7.9 13.11 15.88a16.13 16.13 0 0 1-6 13.25 23.13 23.13 0 0 1-14.85 5.45c-4 0-4.19 0-15.33-3.63a8.27 8.27 0 0 0-2.45-.4c-2 0-3.24.95-3.56 2.69h-.87v-23.77Zm-6.49-9h-1c-.16-5.46-1-7.9-3.71-11.53a18.25 18.25 0 0 0-14.86-7.35H78v22.59h1.5c2.85 0 5.93-1.9 8.14-4.9a10.21 10.21 0 0 0 2.42-7.15v-.71h1v28.2h-1v-.79a12.14 12.14 0 0 0-2.61-7.89c-2.14-2.92-5.61-5.14-8-5.14H78v24.09c0 1.74 1.27 2.38 4.58 2.38 5.69 0 9.25-1.58 12.09-5.46a46.93 46.93 0 0 0 5.74-10.68 17.64 17.64 0 0 0 1.19-7.11h1v24.25H54.28V253h2.6c4.42 0 5.85-1.19 5.85-4.82v-42.56c0-2.3-1.74-3.16-6.16-3.16h-2.29v-1.19h45.58Z"
          />
          <path
            className="cls-1"
            d="M33 201.43c7.59 0 11.14.71 15.41 3.08 3.87 2.13 6 5.45 6 9.4a10 10 0 0 1-3.23 7.35c-2.3 2-4.35 2.85-9.72 4.35 5.92 1 8.6 1.89 11.69 4.1a13.2 13.2 0 0 1 5.29 10.66A12.55 12.55 0 0 1 52 251.52c-3.87 2-7.35 2.6-17.46 2.6H5.62v-1.26h3.55c2.29 0 4-1.43 4-3.32v-44.32c0-1.9-1-2.45-4.5-2.45H6.18l-.09-1.34Zm-4.34 23.62h6.24c3 0 4.27-3.08 4.27-10.11 0-5.37-1-9.87-2.37-11.14-1-.87-1.66-1-4.67-1h-3.44Zm.08 24.33c0 2.37.94 3.64 2.84 3.64h4.22c3.31 0 5-3.4 5-10 0-7.5-.39-10.9-1.66-13.51a6.08 6.08 0 0 0-5.36-3.24h-5Z"
          />
        </g>
        <path
          className="cls-1"
          d="M186.25 60.06c-10.08-10.78-23.43-17.34-35.61-17.34-15.94 0-31.64 9.61-42.41 26-8.22 12.2-12.43 28.21-12.85 48-9.72-10.94-24.95-18-42-18a63 63 0 0 0-25.3 5.39c-5.63 2.59-8.44 4.7-14.3 11.49 2.58-9.38 3.29-12.19 5.39-22.26S21.32 82.78 23 76.92c11 1.18 16.41 1.65 23.2 1.65 30.22 0 46.39-10.32 50.61-32.34l-1.41-.94c-12.42 3.05-16.87 3.52-30.46 3.52-18 0-27.17-.94-39.59-3.52-.94 6.56-1.88 11.49-2.35 14.3-5.43 33.5-6.37 38.41-13.39 64.9h1.87c8-14.29 16.64-20.62 28.82-20.62 8.2 0 15.93 4.22 19.21 10.31 4.45 8.2 6.1 17.57 6.1 35.14 0 15.94-2.11 28.12-6.1 35.16-3.51 6.55-8.19 8.9-17.8 8.9-19.68 0-32.57-9.14-34-24.61a20.74 20.74 0 0 0 14.76 6.09c10.78 0 18.75-8.2 18.75-19.44 0-11.48-8.67-20.39-20.15-20.39C9.14 135 0 145.35 0 159.16c0 21.8 19.21 37 47.1 37s48.9-13.53 56.36-34.34a69.49 69.49 0 0 0 14.61 21.48c8.19 8.44 20.62 13.36 33 13.36 29.53 0 54.13-35.62 54.13-78.5.03-23.16-7.2-45.45-18.95-58.1Zm-23.66 124.42c-2.35 6.31-7.27 9.83-13.13 9.83-6.32 0-10.55-3.52-13.12-11.24-2.58-8-4.45-40.07-4.45-81.3 0-24.14 1.17-39.61 3.51-45.93 2.82-7.26 7.74-11.48 13.36-11.48 7 0 12.65 5.39 14.76 14.53 2.11 8.67 3.52 33.5 3.52 62.55-.04 35.15-1.41 55.77-4.45 63.04Z"
          id="_50"
          data-name={50}
        />
      </g>
    </g>
    <g id="The_World_s" data-name="The World's">
      <path
        className="cls-1"
        d="M85 0h12.83v5.79h-.18a5.52 5.52 0 0 0-1-3.1C96 1.63 94.55.39 93.91.39h-.45v12c0 .64.42 1 1.23 1h.66v.34h-8.13v-.34h.7c.88 0 1.35-.41 1.35-1.16V.39h-.39a4.51 4.51 0 0 0-1.72 1 6.78 6.78 0 0 0-1.61 2.41 3.3 3.3 0 0 0-.34 1.68v.31H85Zm23.14 6V1.3a.85.85 0 0 0-.89-.91h-.37V0h6.71v.39h-.43a.87.87 0 0 0-1 .91v11.23a.78.78 0 0 0 .84.79h.55v.33h-6.71v-.33h.37c.59 0 .89-.44.89-1.2V6.3h-4.26v6.23c0 .5.25.79.72.79h.67v.33h-6.69v-.33h.32a1 1 0 0 0 1-1V1.08c0-.45-.26-.69-.78-.69h-.54V0h6.73v.39h-.33a1 1 0 0 0-1.06.91V6Zm17.66-.82h-.25a4.3 4.3 0 0 0-1-3 4.71 4.71 0 0 0-3.8-1.88h-.61v5.83h.39a2.77 2.77 0 0 0 2.1-1.26 2.63 2.63 0 0 0 .64-1.87v-.17h.27v7.29h-.27v-.21a3.16 3.16 0 0 0-.68-2 3.12 3.12 0 0 0-2.06-1.33h-.39v6.23c0 .45.33.61 1.19.61a3.44 3.44 0 0 0 3.12-1.42 12.51 12.51 0 0 0 1.49-2.76 4.56 4.56 0 0 0 .3-1.83h.27v6.27H114v-.29h.68c1.14 0 1.51-.31 1.51-1.24v-11c0-.59-.45-.82-1.6-.82H114V0h11.8ZM50.47 17.79c-.91 0-1.3.31-1.3.85a5.22 5.22 0 0 0 .12.82l3 11.44 1.61-6.38-1.22-5.3c-.27-1.12-.51-1.37-1.43-1.43v-.52h8.92v.52h-.77a.62.62 0 0 0-.61.67 2.6 2.6 0 0 0 .1.58l2.79 12 2-7.72a10.38 10.38 0 0 0 .32-2.64c0-1.89-.61-2.89-1.74-2.89h-.54v-.58h6.17v.58h-.67a1.51 1.51 0 0 0-1.09.61 14 14 0 0 0-1.83 3.92l-3.85 15.33H57l-2.89-12-3.11 12h-3.27l-4.65-18.76c-.18-.76-.51-1.1-1.06-1.1h-.46v-.58h8.91Z"
      />
      <path
        className="cls-1"
        d="M81.93 19.68a9.87 9.87 0 0 1 3.59 7.66c0 5.39-4.93 10.4-10.25 10.4a11.41 11.41 0 0 1-7.57-3.41 10.06 10.06 0 0 1-2.59-6.78 10.53 10.53 0 0 1 4-8.15 10 10 0 0 1 6.32-2.58 10 10 0 0 1 6.51 2.86m-9.34-.61c-.75 1.77-1.15 4.5-1.15 8.27a28.37 28.37 0 0 0 .77 7.66 3.17 3.17 0 0 0 3 2.35A2.7 2.7 0 0 0 77.67 36c.76-1.55 1.18-4.33 1.18-7.7 0-4.74-.39-8-1.15-9.76a2.59 2.59 0 0 0-2.43-1.31 2.68 2.68 0 0 0-2.68 1.89"
      />
      <path
        className="cls-1"
        d="M94.6 17.21c3.1 0 4.55.28 6.14 1.13a4.46 4.46 0 0 1 2.46 3.77 4.07 4.07 0 0 1-1.18 2.8c-.88.85-1.74 1.28-4 2 2.65.7 3.68 1.24 4.38 2.31.54.82.7 1.55.7 3.56V35c0 .73.18 1.07.58 1.07.76 0 1.34-1.64 1.34-3.83v-1.22h.4v1.49c0 2.16-.39 3.47-1.34 4.23a4.44 4.44 0 0 1-2.94 1 5.32 5.32 0 0 1-3.6-1.39 4.53 4.53 0 0 1-1-3.41c0-.25 0-.85.06-1.34 0-.7.06-1.58.06-1.89 0-1.67-.58-2.49-1.79-2.49h-1.8v8.39c0 .79.39 1.31 1 1.31h1v.58H84.74v-.58h.43c1.28 0 1.85-.43 1.85-1.4V18.77c0-.73-.34-1-1.31-1h-.94v-.61Zm-1.53 9.5h1.37c2.16 0 2.74-.86 2.74-4.11 0-2.1-.13-3.08-.52-3.81a1.89 1.89 0 0 0-1.73-1h-1.86Z"
      />
      <path
        className="cls-1"
        d="M122.7 28.56v9h-17.58V37h.73a1.93 1.93 0 0 0 2-2V19c0-.81-.86-1.36-2.1-1.36h-.64v-.48H117v.48h-.85c-1.46 0-2.13.49-2.13 1.58V35.7c0 .92.52 1.37 1.64 1.37 1.61 0 3.17-1.09 4.68-3.34a9.87 9.87 0 0 0 2-5.17Zm4.44-9.22c0-1.09-.55-1.64-1.73-1.64h-1.55v-.43h10a17.21 17.21 0 0 1 7.54 1.19c2.33 1.22 4 5 4 8.91a10.19 10.19 0 0 1-3.4 7.91c-2 1.73-3.41 2.22-6.55 2.22h-11.59V37h1.3c1.34 0 2-.51 2-1.58ZM135.51 37c1.4 0 2.18-.82 2.64-2.71a32.15 32.15 0 0 0 .7-7.48c0-5.25-.15-6.62-.85-8-.46-.94-1.16-1.22-3.19-1.22-1.31 0-1.8.34-1.8 1.25v17.69c0 .39.12.51.52.51Zm11.69-12.48c2.43-.25 4-1.71 4-3.75a3.94 3.94 0 0 1-2.31.79 2.62 2.62 0 1 1 .12-5.23 3 3 0 0 1 3.07 3.32 5.38 5.38 0 0 1-2.08 4.26 5.48 5.48 0 0 1-2.83 1Zm5.94 3.83c.18 1.45.51 2.28 1.88 4.53 1.94 3.25 3.81 4.74 6 4.74a2.7 2.7 0 0 0 2.56-1 4 4 0 0 0 .88-2.53 3.18 3.18 0 0 0-1.07-2.4c-.87-.76-.87-.76-6.08-2.49-2.76-.95-4.47-3.08-4.47-5.6 0-3.58 3.53-6.57 7.73-6.57a10.33 10.33 0 0 1 3.46.73 6.67 6.67 0 0 0 1.8.31 1.35 1.35 0 0 0 1.49-1.16h.36v7.81h-.36a9.32 9.32 0 0 0-1.8-4.68 5.7 5.7 0 0 0-4.59-2.64 2.48 2.48 0 0 0-2.12.91 3.56 3.56 0 0 0-.82 2.22 3 3 0 0 0 1.21 2.34 3.77 3.77 0 0 0 1.33.73l3.87 1.1c3.31.94 5 3 5 6.11a6.21 6.21 0 0 1-2.35 5.11 8.89 8.89 0 0 1-5.71 2.1c-1.55 0-1.61 0-5.9-1.4a3.25 3.25 0 0 0-.94-.16 1.23 1.23 0 0 0-1.37 1h-.34v-9.11Z"
      />
    </g>
  </svg>
);
