import React, { Component } from "react";

let PRELOADED = [];

class ImagePreloader extends Component {
  loader = new Image();

  constructor(props) {
    super(props);
    this.state = { loaded: 0, src: this.props.src, cached: false };
  }

  componentDidMount() {
    this.load(this.props.src);
  }

  componentDidUpdate(prevProps) {
    let nextProps = this.props;

    if (nextProps.src != prevProps.src) {
      //console.log(`update ${prevProps.src} to ${nextProps.src}`);
      this.load(nextProps.src);
    }
  }

  componentWillUnmount() {}

  load = (src) => {
    // Use global cache of src URLs to bypass image loading if src has loaded before...
    let alreadyloaded = PRELOADED.indexOf(src) != -1;

    if (alreadyloaded) {
      this.loader.onload = () => {};

      //console.log('already loaded',src);
      this.onLoad(src, true); //  Trigger load with cache to true

      return;
    }

    this.loader.onload = () => {
      PRELOADED.push(src);
      this.onLoad(src, false); // Trigger load with cache to false
    };
    this.loader.src = src;
  };

  onLoad = (src, cached = false) => {
    this.setState(
      {
        loaded: true,
        src: src,
        cached: cached,
      },
      () => {
        // Props onLoad callback...
        if (this.props.onLoad) this.props.onLoad({ src: src });
      }
    );
  };

  render() {
    return this.props.children({
      loaded: this.state.loaded,
      src: this.state.src,
      cached: this.state.cached,
    });
  }
}

export default ImagePreloader;
