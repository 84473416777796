import React, { Component } from "react";

class BaseComponent extends Component {
  render() {
    let className = this.props.className ? this.props.className + " " : "";

    if (this.props.family) className += this.props.family;

    if (this.props.modifiers) {
      this.props.modifiers.forEach(
        (m) => (className += " " + this.props.family + "--" + m)
      );
    }

    let Tag = this.props.tag || "div";

    return (
      <Tag {...this.props} className={className}>
        {this.props.children}
      </Tag>
    );
  }
}

export default BaseComponent;
