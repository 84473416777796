import React, { Component } from "react";

import Content from "../_core/components/Content";
import Approach from "../components/Approach";

class Index extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  /*

		RENDER

	*/

  render() {
    let { entry } = this.props;

    return (
      <div className="Template Template--approach">
        <Approach entry={entry} />
      </div>
    );
  }
}

export default Index;
