import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import cookieAPI from "./_cookieAPI.js";
import Cookies from "./Cookies";

import { withStore } from "../../utils/Store";

import "./Cookies.css";

let HAS_INIT = 0;
let ALLOW_TRACKING = 0;

class CookieTracker extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    let hash = this.props.location.hash ? this.props.location.hash : "";
    let prevHash = prevProps.location.hash ? prevProps.location.hash : "";

    const currentPage = this.props.location.pathname + hash;
    const prevPage = prevProps.location.pathname + prevHash;

    const pageHasChanged = currentPage !== prevPage;

    let cookiesPre = document.cookie;

    if (pageHasChanged) {
      if (ALLOW_TRACKING) {
        if (this.props.hasOwnProperty("track")) this.props.track(currentPage);
      } else {
        if (this.props.hasOwnProperty("trackAnyway"))
          this.props.trackAnyway(currentPage);
      }

      if (document.cookie != cookiesPre) {
        let cookies = cookieAPI.getCookies();
        this.props.store.store("cookies", cookies);
      }
    }
  }

  /*

		RENDER

	*/

  render() {
    let com = this;

    return (
      <Cookies>
        {({ cookieAPI, cookies, cookieSettings, doNotTrack, agreeCookies }) => {
          let init = null;

          if (!doNotTrack) ALLOW_TRACKING = agreeCookies;

          if (ALLOW_TRACKING) {
            // Agree to cookies. Initialize scripts
            if (this.props.hasOwnProperty("init") && !HAS_INIT) {
              init = this.props.init();
              HAS_INIT = 1;
            }
          } else {
            // Cookies disabled. Don't inititialise scripts (trigger initAnyway if you want to fire even if tracking disabled)
            if (this.props.hasOwnProperty("initAnyway") && !HAS_INIT) {
              init = this.props.initAnyway();
              HAS_INIT = 1;
            }
          }

          return init;
        }}
      </Cookies>
    );
  }
}

export default withRouter(withStore(CookieTracker));
