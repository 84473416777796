import React, { Component } from "react";
import "../../_core/helpers/String"; // .decodeHTML prototype
import Link from "../../_core/components/Link";
import SiteModifier from "../../_core/components/SiteModifier";
import Media from "../../_core/components/Media";
import Html from "../../_core/components/Html";
import Data from "../../_core/models/Data";

class Posts extends Component {
  constructor(props) {
    super(props);

    this.state = { expanded: false };
  }

  render() {
    let category = this.props.category || false;
    let limit = this.props.limit || 4;

    return (
      <Data>
        {({ entries }) => {
          let posts = entries.filter((e) => e.type == "post");

          // Create a sort value based on sticky and post date
          posts = posts.map((e) => {
            let j = { ...e };
            j.sortVal = (e.sticky ? "1" : "0") + e.postDate;
            return j;
          });

          posts = posts.propSort("sortVal", -1);

          if (category) {
            posts = posts.filter((p) => p.categories.indexOf(category) != -1);
          }

          if (!posts.length) {
            return <div className="Item Item--noresults">No results</div>;
          }

          // Preview the first x posts...
          if (!this.state.expanded && posts.length > limit) {
            // Limit the posts
            posts = posts.limit(limit);

            return (
              <div key={"category"}>
                <ul className={`List List--articles List--collapsed`}>
                  {posts.map((p, pix) => (
                    <li key={`k_${pix}`}>
                      <Link
                        className="Item Item--post"
                        to={`${p.uri}${window.location.hash}`}
                      >
                        <div className="Item-media">
                          <Media id={p.image} fluid={true} />
                        </div>
                        <div className="Item-body">
                          <p className="Item-date">{p.display_date}</p>
                          <h2 className="Item-title">{p.title.decodeHTML()}</h2>
                          <h3 className="Item-subtitle">{p.subtitle}</h3>
                          <p>{p.summary}</p>
                        </div>
                      </Link>
                    </li>
                  ))}
                  <li className="List-item-more">
                    <div
                      className="Link Link--more"
                      onClick={() => this.setState({ expanded: true })}
                    >
                      See more
                    </div>
                  </li>
                </ul>
              </div>
            );
          }

          // Return all posts
          return (
            <div key={"category"}>
              <ul className={`List List--articles List--expanded`}>
                {posts.map((p, pix) => (
                  <li key={`k_${pix}`}>
                    <Link
                      className="Item Item--post"
                      to={`${p.uri}${window.location.hash}`}
                    >
                      <div className="Item-media">
                        <Media id={p.image} fluid={true} />
                      </div>
                      <div className="Item-body">
                        <p className="Item-date">{p.display_date}</p>
                        <h2 className="Item-title">{p.title.decodeHTML()}</h2>
                        <h3 className="Item-subtitle">{p.subtitle}</h3>
                        <p className="Item-summary">{p.summary}</p>
                      </div>
                    </Link>
                  </li>
                ))}
                {(() => {
                  if (posts.length > limit) {
                    return (
                      <li className="List-item-more">
                        <div
                          className="Link Link--more"
                          onClick={() => this.setState({ expanded: false })}
                        >
                          Close
                        </div>
                      </li>
                    );
                  }
                })()}
              </ul>
            </div>
          );
        }}
      </Data>
    );
  }
}

export default Posts;
