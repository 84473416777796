import React, { Component } from "react";
import "../../_core/helpers/String"; // .decodeHTML prototype
import Link from "../../_core/components/Link";
import SiteModifier from "../../_core/components/SiteModifier";
import Content from "../../_core/components/Content";
import Data from "../../_core/models/Data";

class Popup extends Component {
  render() {
    let modifier = this.props.modifier || "default";

    return (
      <Data require={["HOME"]}>
        {({ HOME }) => {
          if (HOME.showPopup) {
            return (
              <SiteModifier modifier={"noMessage,open"}>
                <div className="Site-message">
                  <div className="Site-message-content">
                    <a className="Site-message-close"></a>

                    <Content html={HOME.popupMessage} />
                  </div>

                  <SiteModifier modifier={"noMessage"}>
                    <div className="Site-message-bg" />
                  </SiteModifier>
                </div>
              </SiteModifier>
            );
          }
        }}
      </Data>
    );
  }
}

export default Popup;
