import React, { Component } from "react";
import "../../_core/helpers/String"; // .decodeHTML prototype
import Link from "../../_core/components/Link";
import SiteModifier from "../../_core/components/SiteModifier";
import Html from "../../_core/components/Html";
import Data from "../../_core/models/Data";

function Submenu({ menu, modifier = "sub" }) {
  if (menu.hasOwnProperty("children") && menu.children.length) {
    let isActive = false;

    let active = menu.children.find((c) =>
      window.location.pathname.match(c.uri)
    );

    if (active) isActive = true;

    return (
      <ul
        className={`List List--${modifier} ${
          isActive ? "is-active" : "is-inactive"
        }`}
      >
        {menu.children.map((v, vix) => (
          <MenuItem key={`k_${vix}`} menu={v}>
            <Link to={`${v.uri}`} navlink>
              {v.title.decodeHTML()}
            </Link>
          </MenuItem>
        ))}
      </ul>
    );
  } else {
    return null;
  }
}

function MenuItem({ menu, children }) {
  // Always close nav on click
  return (
    <SiteModifier demodifier="menu">
      <li>{children}</li>
    </SiteModifier>
  );

  if (menu.hasOwnProperty("children") && menu.children.length) {
    return <li>{children}</li>;
  } else {
    return (
      <SiteModifier demodifier="menu">
        <li>{children}</li>
      </SiteModifier>
    );
  }
}

class Nav extends Component {
  render() {
    let modifier = this.props.modifier || "default";

    return (
      <Data>
        {({ structures }) => {
          if (!structures.hasOwnProperty(this.props.label)) return null;

          let nav = structures[this.props.label];

          return (
            <ul className={`List List--nav List--${modifier}`}>
              {nav.map((v, vix) => (
                <MenuItem key={`k_${vix}`} menu={v}>
                  <Link to={`${v.uri}`} navlink>
                    {v.title}
                  </Link>
                  <Submenu menu={v} />
                </MenuItem>
              ))}
            </ul>
          );
        }}
      </Data>
    );
  }
}

export default Nav;
