import React, { Component } from "react";
import "../../_core/helpers/String"; // .decodeHTML prototype
import Link from "../../_core/components/Link";
import SiteModifier from "../../_core/components/SiteModifier";
import RenderLoop from "../../_core/utils/RenderLoop";
import Html from "../../_core/components/Html";
import Icon from "../../_core/components/Icon";
import Content from "../../_core/components/Content";
import FadeIn from "../../_core/components/FadeIn";
import Data from "../../_core/models/Data";

import Svg from "./Svg";

const SLUGS = {
  mexico: "mexican-inspiration",
  britain: "british-ingredients",
  europe: "eastern-european-wines",
};

const { TimelineMax, TweenLite, Expo, Quad } = window;

const Ease = Expo;

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}
function isActive(entry) {
  return window.location.hash.match(entry.slug);
}
function ToggleLink(props) {
  let active = window.location.hash.match(props.slug);

  let to = active ? window.location.hash.split(props.slug + "/")[0] : props.to;
  return (
    <Link to={to} className={active ? "is-active" : "is-inactive"}>
      {props.children}
    </Link>
  );
}

class Approach extends Component {
  bg = false;
  reverse = false;
  anim = false;
  pos = 0;
  max = 50;

  constructor(props) {
    super(props);
    this.state = { hover: 0, hoverRegion: 0 };
  }

  hover = (slug) => {
    if (slug) {
      // Normalize the slug so we only set the class to the state
      let key = getKeyByValue(SLUGS, slug);
      this.setState({ hover: key }, () => {
        this.moveTo(slug);
      });
    } else {
      this.setState({ hover: false });
    }
  };

  frame = () => {
    if (!this.bg || this.state.hover) return;

    if (this.anim) this.anim.kill();

    let max = this.max;

    this.pos += 0.01;
    TweenLite.set(this.bg, { x: `-${this.pos}%` });
    TweenLite.set(this.reverse, { x: `-${max - this.pos}%` });
    // max 50 percent
    if (this.pos >= 50) this.pos = 0;
  };

  moveTo = (slug) => {
    if (!this.bg) return;

    let x = 0;

    switch (slug) {
      case SLUGS.mexico:
        x = 0;
        break;
      case SLUGS.britain:
        x = 10;
        break;
      case SLUGS.europe:
        x = 12;
        break;
    }

    let time = 1.2;
    let rx = this.max - x;
    let dist = x - this.pos;
    let start = this.pos;

    this.anim = new TimelineMax({ paused: true });

    this.anim.to(
      this.bg,
      time,
      {
        x: `-${x}%`,
        ease: Ease.easeOut,
        onUpdate: () => {
          if (this.anim) {
            let progress = this.anim.progress();

            let pos = Math.abs(this.bg._gsTransform.xPercent);
            this.pos = pos;
          }
        },
        onComplete: () => {
          this.pos = x;
          this.anim = false;
        },
      },
      0
    );
    this.anim.to(
      this.reverse,
      time * 1.01,
      { x: `-${rx}%`, ease: Ease.easeOut, onComplete: () => {} },
      0
    );

    this.anim.play();
  };

  render() {
    let modifier = this.props.modifier || "default";

    return (
      <Data>
        {({ entries, categories }) => {
          let zoom = "off";
          let zoomed = false;

          let sections = entries.filter((e) => e.parent == this.props.entry.id);

          let mexico = sections.find((s) => s.slug == SLUGS.mexico);
          let britain = sections.find((s) => s.slug == SLUGS.britain);
          let europe = sections.find((s) => s.slug == SLUGS.europe);

          let activeregion = false;

          // Get the child entries for each
          sections.forEach((s) => {
            s.regions = entries.filter((e) => e.parent == s.id);
            s.active = window.location.hash.match(s.uri);
            if (s.active) {
              zoomed = true;
              zoom = getKeyByValue(SLUGS, s.slug);

              // This one is active - is a child active?
              s.regions.forEach((r) => {
                if (isActive(r)) activeregion = r;
              });
            }
          });

          let modifier = this.state.hover ? this.state.hover : "default";

          return (
            <RenderLoop onFrame={this.frame}>
              <div
                className={`Approach Approach--${modifier} Approach--${
                  zoomed ? "zoomed" : "not-zoomed"
                } Approach--zoom-${zoom}`}
              >
                <div className="Approach-title">{this.props.entry.title}</div>
                <div className="Approach-media">
                  <div className="Approach-map">
                    <div
                      className="Approach-map-bg"
                      ref={(el) => (this.bg = el)}
                    >
                      <div className="Approach-highlight Approach-highlight--mexico" />
                      <div className="Approach-highlight Approach-highlight--britain" />
                      <div className="Approach-highlight Approach-highlight--europe" />
                    </div>
                    <div
                      className="Approach-map-reverse"
                      ref={(el) => (this.reverse = el)}
                    />
                  </div>
                  {sections.map((s) => {
                    let modifier = getKeyByValue(SLUGS, s.slug);
                    return (
                      <div className={`Approach-svg Approach-svg--${modifier}`}>
                        <Svg
                          regions={s.regions.map((r) => ({
                            title: r.title,
                            active:
                              this.state.hoverRegion == r.id || isActive(r),
                            uri: `#/explore${r.uri}`,
                            layer_id: r.layer_id,
                          }))}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className="Approach-body">
                  <div>
                    {(() => {
                      if (activeregion) {
                        return (
                          <FadeIn key={activeregion.id}>
                            <div className="Approach-content">
                              <div className="Approach-back">
                                <Link to={`#/explore`}>Back</Link>
                              </div>
                              <Content
                                modifier="approach"
                                html={activeregion.content}
                              />
                            </div>
                          </FadeIn>
                        );
                      } else {
                        return (
                          <FadeIn key={"inactive"}>
                            <ul className="List List--explore">
                              {sections.map((s) => {
                                return (
                                  <li>
                                    <ToggleLink
                                      slug={s.slug}
                                      to={`#/explore${s.uri}`}
                                    >
                                      <span
                                        onMouseEnter={() => this.hover(s.slug)}
                                        onMouseLeave={() => this.hover(0)}
                                      >
                                        {s.title.decodeHTML()}
                                      </span>
                                    </ToggleLink>

                                    <ul className="List List--regions">
                                      {s.regions.map((r) => {
                                        return (
                                          <li>
                                            <Link to={`${r.uri}`}>
                                              <span
                                                onMouseEnter={() =>
                                                  this.setState({
                                                    hoverRegion: r.id,
                                                  })
                                                }
                                                onMouseLeave={() =>
                                                  this.setState({
                                                    hoverRegion: false,
                                                  })
                                                }
                                              >
                                                {r.title.decodeHTML()}
                                              </span>
                                            </Link>
                                            <Content html={r.content} />
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </li>
                                );
                              })}
                            </ul>
                          </FadeIn>
                        );
                      }
                    })()}
                  </div>
                </div>
              </div>
            </RenderLoop>
          );
        }}
      </Data>
    );
  }
}

export default Approach;
