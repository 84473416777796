import React, { Component } from "react";

import Media from "../../_core/components/Media";
import Link from "../../_core/components/Link";
import Content from "../../_core/components/Content";
import SiteModifier from "../../_core/components/SiteModifier";
import Data from "../../_core/models/Data";
import Blocks from "../../components/Blocks";

class ProjectView extends Component {
  render() {
    let project = this.props.project;

    return (
      <Data>
        {({ HOME }) => {
          return (
            <div className="Item--projectView">
              <div className="Item-body">
                <Content modifier="project">
                  <h1>{project.title}</h1>
                  <h2>{project.project_notable_names}</h2>
                  <h3>{project.project_location}</h3>
                </Content>
                <Content html={project.content} />
              </div>

              <div className="Item-media">
                {project.project_images.map((i, ix) => (
                  <div
                    key={`project_image_${ix}`}
                    className="Item-media-item"
                    data-style={i.style}
                    data-size={i.size}
                  >
                    <Media id={i.image.id} fluid={true} lazy={true} />
                  </div>
                ))}
              </div>
            </div>
          );
        }}
      </Data>
    );
  }
}

export default ProjectView;
