import React, { Component } from "react";

import Link from "../../_core/components/Link";
import Content from "../../_core/components/Content";
import SiteModifier from "../../_core/components/SiteModifier";
import Data from "../../_core/models/Data";

class Footer extends Component {
  render() {
    return (
      <Data>
        {({ HOME }) => {
          return (
            <div>
              <div className="Footer-section">
                <Content html={HOME.footerContact} />
              </div>
            </div>
          );
        }}
      </Data>
    );
  }
}

export default Footer;
