import React, { Component } from "react";

// Conf
import conf from "./_core/_conf";

// Core
import Loader from "./_core/components/Loader";
import FadeIn from "./_core/components/FadeIn";
import SiteModifier from "./_core/components/SiteModifier";
import Site from "./_core/utils/Site";

// Project
import Router from "./components/Router";
import Logo from "./components/Logo";

import * as Browser from "./_core/components/ModernBrowser";

// Cookies
import CookieManager from "./_core/components/Cookie/CookieManager";
import CookieMessage from "./_core/components/Cookie/CookieMessage";
import CookieTracker from "./_core/components/Cookie/CookieTracker";

// Tracking
import GoogleAnalytics from "react-ga";
import ReactPixel from "react-facebook-pixel";
import GoogleTagManager from "./_core/components/GoogleTagManager";

const GA_ID = "UA-177290917-1";
const PIXEL_ID = "3053068671391738";
const GTM_ID = "GTM-NTHBRCQ";

class Index extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    // Load the settings to get the manifest (JSON data to power the site)
    let settings = conf.get();

    return (
      <React.Fragment>
        <Site>
          <SiteModifier modifier="loading" auto />

          <SiteModifier modifier="open">
            <div className="Intro">
              <h1 className="Intro-logo">
                <span>KOL Restaurant</span>
                <Logo />
              </h1>
              <h2 className="Intro-quote">
                Mexican Soul. British Ingredients.
              </h2>
            </div>
          </SiteModifier>

          <Browser.Modern>
            {() => (
              <React.Fragment>
                <CookieTracker
                  init={() => {
                    // Agreed cookies...

                    // GA
                    GoogleAnalytics.initialize({
                      trackingId: GA_ID,
                      debug: true,
                    });

                    // Facebook Pixel
                    const advancedMatching = {};
                    const options = {
                      autoConfig: true, // set pixel's autoConfig
                      debug: false, // enable logs
                    };
                    ReactPixel.init(PIXEL_ID, advancedMatching, options);
                    ReactPixel.pageView();

                    // Return GTM tag
                    return (
                      <div className="CT-init">
                        <GoogleTagManager gtmId={GTM_ID} />
                      </div>
                    );
                  }}
                  track={(page) => {
                    // Code to update on each page view
                    GoogleAnalytics.pageview(page);

                    ReactPixel.pageView();
                  }}
                />
                <Loader
                  wait={0}
                  manifest={settings.MANIFEST}
                  progress={() => {
                    return null;
                  }}
                  loaded={() => (
                    <React.Fragment>
                      <SiteModifier modifier="loaded" auto />
                      <FadeIn duration={0.3}>
                        <Router />
                      </FadeIn>
                    </React.Fragment>
                  )}
                />
                <CookieMessage />
              </React.Fragment>
            )}
          </Browser.Modern>
          <Browser.Old>
            {() => {
              // Set html flag to basic mode, don't return the app
              document.documentElement.className =
                "basic-mode Html--loaded" +
                document.documentElement.className.replace("rich-mode", "");
              return null;
            }}
          </Browser.Old>
        </Site>
      </React.Fragment>
    );
  }
}

export default Index;
