import React, { Component } from "react";
import Image from "../Image";

class Gallery extends Component {
  timer;
  id = Math.round(Math.random() * 1000);

  constructor(props) {
    super(props);

    let initialSlide = props.hasOwnProperty("initialSlide")
      ? props.initialSlide
      : 0;
    this.state = {
      active: initialSlide,
      images: [
        { caption: "Image 1", src: "/ui/img/image-01.jpg" },
        { caption: "Image 2", src: "/ui/img/image-02.jpg" },
        { caption: "Image 3", src: "/ui/img/image-03.jpg" },
      ],
    };
  }

  componentDidMount() {
    this.id = Math.round(Math.random() * 1000);

    if (this.props.autoplay) {
      let wait = 0;
      let _this = this;

      if (this.props.delay) wait = this.props.delay;
      if (this.props.wait) wait += this.props.wait;

      // Init 1
      _this.updateIx(0);

      setTimeout(function () {
        _this.next();
      }, wait);
    }

    if (this.props.preload) {
      this.preload();
    }
  }

  componentWillUnmount() {
    if (this.timer) clearInterval(this.timer);
  }
  autoplay = () => {
    if (this.props.autoplay) {
      if (this.timer) clearInterval(this.timer);

      var int = this.props.delay ? this.props.delay : 1000;
      this.timer = setInterval(this.next, int);
    }
  };
  preload = () => {
    this.state.images.map((i) => {
      if (i.src) {
        let img = new Image();
        img.src = i.src;
      }
      if (i.asset) {
        let imgL = new Image();
        imgL.src = i.asset.large;

        let imgS = new Image();
        imgS.src = i.asset.small;
      }
    });
  };

  prev = (timer = 0) => {
    if (this.timer) clearInterval(this.timer);
    setTimeout(() => {
      let ix = this.state.active;
      ix--;

      this.autoplay(); // reset timer

      this.updateIx(ix);
    }, timer);
  };

  next = (timer = 0) => {
    if (this.timer) clearInterval(this.timer);
    setTimeout(() => {
      let ix = this.state.active;
      ix++;

      this.autoplay(); //reset timer

      this.updateIx(ix);
    }, timer);
  };

  updateIx = (ix) => {
    let items = this.props.images || this.props.items || [];

    const last = items.length - 1;

    // Ensure ix is within range
    if (ix < 0) ix = last;
    if (ix > last) ix = 0;

    this.setState({ active: ix }, function () {
      if (this.props.hasOwnProperty("onChange")) this.props.onChange(ix, items);
    });
  };

  item = (item, ix, opts = {}) => {
    let items = this.props.images || this.props.items || [];

    if (!items.length) return null;

    const active = ix == this.state.active;
    const asset = item.asset || item;
    const styles = {
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      display: "block",
    };

    const style = active
      ? { ...styles, opacity: 1, pointerEvents: "all" }
      : { ...styles, opacity: 0, pointerEvents: "none" };

    let itemHTML = this.props.getItem ? (
      this.props.getItem(item, ix, active, this)
    ) : (
      <Image fluid={true} {...asset} />
    );

    if (opts.hasOwnProperty("placeholder") && opts.placeholder) itemHTML = null;

    return (
      <div key={"gall_" + this.id + "_" + ix} style={style}>
        {itemHTML}
      </div>
    );
  };

  active = () => {
    let items = this.props.images || this.props.items || [];

    if (!items.length) return null;

    if (this.props.getActive)
      return this.props.getActive(items[this.state.active]);

    if (items[this.state.active].asset)
      return <Image fluid={true} {...items[this.state.active].asset} />;
    else return <Image fluid={true} {...items[this.state.active]} />;
  };

  caption = () => {
    let items = this.props.images || this.props.items || [];

    if (!items.length) return null;

    if (this.props.getCaption)
      return this.props.getCaption(items[this.state.active]);

    return items[this.state.active].caption;
  };

  render() {
    let items = this.props.images || this.props.items || [];

    const nav =
      items.length > 1 ? (
        <div className="Gallery-navigation">
          <div className="Gallery-prev">
            <div style={{ cursor: "pointer" }} onClick={this.prev}>
              <div className="Icon Icon--arrow-left" />
            </div>
          </div>
          <div className="Gallery-next">
            <div style={{ cursor: "pointer" }} onClick={this.next}>
              <div className="Icon Icon--arrow-right" />
            </div>
          </div>
        </div>
      ) : null;

    let modifier = this.props.modifier
      ? "Gallery--" + this.props.modifier
      : null;

    let style = null;

    if (this.props.fluid) {
      style = {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      };
    }

    return (
      <div className={"Gallery " + modifier} style={style}>
        {nav}

        <div onClick={this.next} className="Gallery-body">
          {(() => {
            if (this.props.slidesToLoad) {
              let min = this.state.active - 1; // Start before this item
              let max = this.state.active + this.props.slidesToLoad;
              let last = items.length - 1;
              if (max > last) max = last;

              max += 1; // .slice is not inclusive

              let set = items.slice(min, max);

              let diff = Math.abs(set.length - this.props.slidesToLoad);

              // Push items on to the front if slidesToLoad means there's surplus
              for (let i = 0; i < diff; i++) {
                set.unshift(items[i]);
              }
              // Add the last item on to then end for the last item
              if (!this.state.active) {
                set.push(items[last]);
              }

              return items.map((item, ix) => {
                if (set.indexOf(item) != -1) {
                  return this.item(item, items.indexOf(item));
                } else {
                  return this.item(item, items.indexOf(item), {
                    placeholder: true,
                  });
                }
              });
            } else {
              return items.map((item, ix) => this.item(item, ix));
            }
          })()}
        </div>
        <div className="Gallery-prev" onClick={this.prev}>
          {this.props.prev}
        </div>
        <div className="Gallery-next" onClick={this.next}>
          {this.props.next}
        </div>
        <div className="Gallery-caption">{this.caption()}</div>
      </div>
    );
  }
}

export default Gallery;
