// Global data
class Config {
  data = {};
  id = Math.random();

  constructor() {}

  set(data) {
    this.data = data;
  }

  get() {
    return this.data;
  }
}

export default new Config();
