import React, { Component } from "react";
import { Helmet } from "react-helmet";
import "../../_core/helpers/String"; // .decodeHTML prototype

import Data from "../../_core/models/Data";

class Meta extends Component {
  render() {
    return (
      <Data require={["META"]}>
        {({ META }) => {
          if (META) {
            return (
              <Helmet>
                <meta
                  property="og:description"
                  content={META.metaDescription}
                />
                <meta name="description" content={META.metaDescription} />
                <meta
                  name="twitter:description"
                  content={META.metaDescription}
                />
                <meta name="keywords" content={META.metaKeywords} />
                <title>
                  {META.metaTitle
                    ? META.metaTitle.decodeHTML()
                    : META.title.decodeHTML()}{" "}
                  • KOL Restaurant
                </title>
                {(() => {
                  if (META.hasOwnProperty("schema") && META.schema) {
                    return (
                      <script type="application/ld+json">
                        {JSON.stringify(META.schema)}
                      </script>
                    );
                  } else {
                    return null;
                  }
                })()}
              </Helmet>
            );
          } else {
            return null;
          }
        }}
      </Data>
    );
  }
}

export default Meta;
