let React = require("react");
let ReactDOM = require("react-dom");
let PropTypes = require("prop-types");
let createReactClass = require("create-react-class");

let containmentPropType = PropTypes.any;

if (typeof window !== "undefined") {
  containmentPropType = PropTypes.instanceOf(window.Element);
}

function throttle(callback, limit) {
  var wait = false;
  return function () {
    if (!wait) {
      wait = true;
      setTimeout(function () {
        callback();
        wait = false;
      }, limit);
    }
  };
}

function debounce(func, wait) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      func.apply(context, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

module.exports = createReactClass({
  displayName: "AtOffset",

  propTypes: {
    onChange: PropTypes.func,
    active: PropTypes.bool,
    partialVisibility: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.oneOf(["top", "right", "bottom", "left"]),
    ]),
    delayedCall: PropTypes.bool,
    offset: PropTypes.oneOfType([
      PropTypes.shape({
        top: PropTypes.number,
        left: PropTypes.number,
        bottom: PropTypes.number,
        right: PropTypes.number,
      }),
      // deprecated offset property
      PropTypes.shape({
        direction: PropTypes.oneOf(["top", "right", "bottom", "left"]),
        value: PropTypes.number,
      }),
    ]),
    scrollCheck: PropTypes.bool,
    scrollDelay: PropTypes.number,
    scrollThrottle: PropTypes.number,
    resizeCheck: PropTypes.bool,
    resizeDelay: PropTypes.number,
    resizeThrottle: PropTypes.number,
    intervalCheck: PropTypes.bool,
    intervalDelay: PropTypes.number,
    containment: containmentPropType,
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    minTopValue: PropTypes.number,
  },

  getDefaultProps: function () {
    return {
      active: true,
      partialVisibility: false,
      minTopValue: 0,
      scrollCheck: false,
      scrollDelay: 250,
      scrollThrottle: -1,
      resizeCheck: false,
      resizeDelay: 250,
      resizeThrottle: -1,
      intervalCheck: true,
      intervalDelay: 100,
      delayedCall: false,
      offset: {},
      containment: null,
      children: React.createElement("span"),
    };
  },

  getInitialState: function () {
    return {
      isVisible: null,
      visibilityRect: {},
    };
  },

  componentDidMount: function () {
    this.node = ReactDOM.findDOMNode(this);
    if (this.props.active) {
      this.startWatching();
    }
  },

  componentWillUnmount: function () {
    this.stopWatching();
  },

  componentWillReceiveProps: function (nextProps) {
    if (nextProps.active && !this.props.active) {
      this.setState(this.getInitialState());
      this.startWatching();
    } else if (!nextProps.active) {
      this.stopWatching();
    }
  },

  getContainer: function () {
    return this.props.containment || window;
  },

  addEventListener: function (target, event, delay, throttle) {
    if (!this.debounceCheck) {
      this.debounceCheck = {};
    }

    var timeout;
    var func;

    var later = function () {
      timeout = null;
      this.check();
    }.bind(this);

    if (throttle > -1) {
      func = function () {
        if (!timeout) {
          timeout = setTimeout(later, throttle || 0);
        }
      };
    } else {
      func = function () {
        clearTimeout(timeout);
        timeout = setTimeout(later, delay || 0);
      };
    }

    var info = {
      target: target,
      fn: func,
      getLastTimeout: function () {
        return timeout;
      },
    };

    target.addEventListener(event, info.fn);
    this.debounceCheck[event] = info;
  },

  startWatching: function () {
    if (this.debounceCheck || this.interval) {
      return;
    }

    if (this.props.intervalCheck) {
      this.interval = setInterval(this.check, this.props.intervalDelay);
    }

    if (this.props.scrollCheck) {
      this.addEventListener(
        this.getContainer(),
        "scroll",
        this.props.scrollDelay,
        this.props.scrollThrottle
      );
    }

    if (this.props.resizeCheck) {
      this.addEventListener(
        window,
        "resize",
        this.props.resizeDelay,
        this.props.resizeThrottle
      );
    }

    // if dont need delayed call, check on load ( before the first interval fires )
    !this.props.delayedCall && this.check();
  },

  stopWatching: function () {
    if (this.debounceCheck) {
      // clean up event listeners and their debounce callers
      for (var debounceEvent in this.debounceCheck) {
        if (this.debounceCheck.hasOwnProperty(debounceEvent)) {
          var debounceInfo = this.debounceCheck[debounceEvent];

          clearTimeout(debounceInfo.getLastTimeout());
          debounceInfo.target.removeEventListener(
            debounceEvent,
            debounceInfo.fn
          );

          this.debounceCheck[debounceEvent] = null;
        }
      }
    }
    this.debounceCheck = null;

    if (this.interval) {
      this.interval = clearInterval(this.interval);
    }
  },

  /**
   * Check if the element is within the visible viewport
   */
  check: function () {
    var el = this.node;
    var rect;
    var containmentRect;
    // if the component has rendered to null, dont update visibility
    if (!el) {
      return this.state;
    }

    rect = el.getBoundingClientRect();

    if (this.props.containment) {
      var containmentDOMRect = this.props.containment.getBoundingClientRect();
      containmentRect = {
        top: containmentDOMRect.top,
        left: containmentDOMRect.left,
        bottom: containmentDOMRect.bottom,
        right: containmentDOMRect.right,
      };
    } else {
      containmentRect = {
        top: 0,
        left: 0,
        bottom: window.innerHeight || document.documentElement.clientHeight,
        right: window.innerWidth || document.documentElement.clientWidth,
      };
    }

    var height = this.props.distance
      ? this.props.distance
      : Math.abs(rect.top - rect.bottom);

    var visibilityRect = {
      top: rect.top <= this.props.offset.top,
      bottom: rect.top >= this.props.offset.top - height,
      left: true,
      right: true,
    };

    var isVisible =
      visibilityRect.top &&
      visibilityRect.left &&
      visibilityRect.bottom &&
      visibilityRect.right;

    // if(this.props.debug) console.log('ATOFFSET',rect.top,rect.bottom,height,this.props.offset.top,isVisible,'BETWEEN',this.props.offset.top + height)

    var state = this.state;
    // notify the parent when the value changes
    if (this.state.isVisible !== isVisible) {
      state = {
        isVisible: isVisible,
        visibilityRect: visibilityRect,
      };
      this.setState(state);
      if (this.props.onChange) this.props.onChange(isVisible, visibilityRect);
    }

    return state;
  },

  render: function () {
    if (this.props.children instanceof Function) {
      return this.props.children({
        isVisible: this.state.isVisible,
        offset: this.state.offset,
      });
    }
    return React.Children.only(this.props.children);
  },
});
