import React, { Component } from "react";
import Base from "../BaseComponent";

class Icon extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let type = this.props.glyph ? "glyph-" + this.props.type : this.props.type;

    return (
      <Base key={this.props.key} modifiers={[type]} family="Icon">
        {this.props.children}
      </Base>
    );
  }
}

export default Icon;
