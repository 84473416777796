import React, { Component } from "react";

import Link from "../../_core/components/Link";
import Content from "../../_core/components/Content";
import SiteModifier from "../../_core/components/SiteModifier";
import Data from "../../_core/models/Data";
import Blocks from "../../components/Blocks";

class ContactForm extends Component {
  render() {
    return (
      <Data>
        {({ HOME }) => {
          return (
            <div>
              <Content html={HOME.contactText} />
              <Blocks
                blocks={[
                  {
                    type: "form",
                    fields: [
                      {
                        name: "firstName",
                        placeholder: "First Name",
                        type: "text",
                      },
                      {
                        name: "lastName",
                        placeholder: "Last Name",
                        type: "text",
                      },
                      {
                        name: "telephone",
                        placeholder: "Mobile or Landline",
                        type: "text",
                      },
                      {
                        name: "message",
                        placeholder: "Accompanying message",
                        type: "textarea",
                      },
                    ],
                  },
                ]}
                entry={HOME}
                modifiers={["debug"]}
              />
            </div>
          );
        }}
      </Data>
    );
  }
}

export default ContactForm;
