import React, { Component } from "react";

import Content from "../_core/components/Content";

class Index extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  /*

		RENDER

	*/

  render() {
    return (
      <div className="Template Template--default">
        <div className="Template-head">
          <Content>
            <h1></h1>
          </Content>
        </div>
        <div className="Template-body">
          <Content>
            <h1>Page Not Found</h1>
            <p>
              Try our <a href="/">Home Page</a> instead.
            </p>
          </Content>
        </div>
      </div>
    );
  }
}

export default Index;
