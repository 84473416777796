export default function hashLink({ key, value, toggle, multi, removeEmpty }) {
  let hash = window.location.hash == "" ? "#/" : window.location.hash;

  let out = hash;

  // If hash val is supplied
  let replace = value || removeEmpty ? key + "/[^/]+/" : key + "/";
  let regex = new RegExp(replace, "g");

  let uri = value ? `${key}/${value}/` : `${key}/`;
  uri = uri.replace(/\/\//gi, "/");

  // If remove flag sent in and no value, clear the uri
  if (removeEmpty) {
    if (!value) uri = "";
  }

  // Remove references to the same key unless multi option is passed in
  if (!multi) {
    out = out.replace(regex, "");
  }

  // Remove the URI if in the window has already
  if (toggle) {
    // Did the original hash contian this uri? If so, remove it so it toggles off
    out = hash.match(uri) ? out.replace(uri, "") : out + uri;
  } else {
    out = out.replace(regex, "") + uri;
  }

  return out;
}
