import React, { Component } from "react";

import Index from "./index";

class Home extends Component {
  render() {
    return <Index entry={this.props.entry} />;
  }
}

export default Home;
