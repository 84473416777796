import React, { Component } from "react";

import AtOffset from "../../utils/AtOffset";

import { withSite } from "../../utils/Site";

class ModifierSection extends Component {
  isVisible = false;

  constructor(props) {
    super(props);
    this.state = { isVisible: false };
  }

  onChange = (isVisible) => {
    if (this.props.modifier) {
      if (isVisible) {
        this.props.site.modify(this.props.modifier);
      } else {
        if (isVisible != this.isVisible)
          this.props.site.deModify(this.props.modifier);
      }
    }

    this.isVisible = isVisible;
  };

  componentWillUnmount() {
    this.props.site.deModify(this.props.modifier);
  }

  render() {
    let { children } = this.props;
    let className = this.props.className || "";

    let pos = this.props.pos || 0;

    return (
      <AtOffset
        onChange={this.onChange}
        offset={{ top: pos }}
        distance={this.props.distance}
        debug={this.props.debug}
      >
        {({ isVisible, offset }) => {
          let visibleClass = isVisible ? "active" : "inactive";
          return (
            <div
              className={className + " is-" + visibleClass}
              data-modifier={this.props.modifier}
            >
              {children}
            </div>
          );
        }}
      </AtOffset>
    );
  }
}

export default withSite(ModifierSection);
