import React, { Component } from "react";

import { withRouter } from "react-router-dom";

import { withStore } from "../../utils/Store";

const Templates = {};
const req = require.context("../../../templates", false, /^(?!.*_).*\.js$/);

req.keys().forEach((filename) => {
  Templates[filename.replace("./", "").replace(".js", "")] =
    req(filename).default;
});

class Template extends Component {
  template = null;
  entry = null;
  found = null;
  constructor(props) {
    super(props);
    this.state = { template: null, entry: null };
  }

  shouldComponentUpdate = (nextProps) => {
    if (this.props.hasOwnProperty("update")) {
      let should = this.props.update(nextProps);

      return should;
    }
    return true;
  };

  componentDidMount = () => {
    this.setData(this.props);
  };

  componentWillReceiveProps(nextProps) {
    let hash = this.props.location.hash ? this.props.location.hash : "";
    let nextHash = nextProps.location.hash ? nextProps.location.hash : "";

    const currentPage = this.props.location.pathname + hash;
    const nextPage = nextProps.location.pathname + nextHash;

    const entries = this.props.data.entries;
    const nextEntries = nextProps.data.entries;

    const key = this.props.key;
    const nextKey = nextProps.key;

    if (
      currentPage !== nextPage ||
      (!entries && nextEntries) ||
      key != nextKey
    ) {
      this.setData(nextProps);
    }
  }

  setData = (props) => {
    let entry;
    let template;
    let found = false;
    let { location } = props;
    let pathname = location.pathname;

    if (!props.entry) {
      // Lookup the entry
      const { entries } = props.data;

      if (entries) {
        const match = entries.find((i) => {
          return i.uri.replace(/\/$/, "") == pathname.replace(/\/$/, "");
        });

        found = typeof match != "undefined";

        if (found) {
          entry = match;
        }
      } else {
        found = true;
        let file = pathname == "/" ? "index" : pathname.split("/")[1];
        entry = {
          template: file,
        };
      }
    } else {
      if (props.entry.id) {
        entry = props.entry;
        found = true;
      } else {
        found = false;
      }
    }

    if (!props.template) {
      // Lookup the template

      if (found) {
        //console.log('ENTRY',entry,entry.template);
        if (Templates.hasOwnProperty(entry.template)) {
          template = entry.template;
        } else if (Templates.hasOwnProperty(entry.slug)) {
          template = entry.slug;
        } else if (Templates.hasOwnProperty(entry.type)) {
          template = entry.type;
        } else {
          template = "default";
        }
      } else {
        // Not found...does it match the URI?
        let uriMatch = 0;
        for (let k in Templates) {
          if (Templates.hasOwnProperty(k)) {
            // Base the pattern on the key, but add a leading slash and swap wildcards for regex
            var pattern = "/" + k.replace("*", "(.*)");

            var re = new RegExp(pattern, "gi");

            let match = location.pathname.match(re);

            if (match) {
              uriMatch = k;
              break;
            }
          }
        } // End for

        if (uriMatch) {
          template = uriMatch;
        } else {
          template = "error";
        }
      }
    } else {
      template = props.template;
    }

    if (!Templates.hasOwnProperty(template)) {
      if (Templates.hasOwnProperty("index") && pathname == "/") {
        template = "index";
      } else {
        template = "error";
      }
    }

    // Store the values - either from props or automatic
    this.entry = entry;
    this.template = template;
    this.found = found;

    // Set the state to render
    this.setState({ entry: entry, template: template });

    return;
  };

  render() {
    if (!this.template) return null;

    const Output = Templates[this.template];

    let rendered = Output ? (
      <Output entry={this.entry} />
    ) : (
      <div>{this.template}.js not found</div>
    );

    if (this.props.children) {
      let children = this.props.children({
        found: this.found,
        entry: this.entry,
        template: this.template,
        uri: window.location.pathname,
        url: window.location.href,
      });

      return [
        { ...rendered, key: 0 },
        { ...children, key: 1 },
      ];
    }

    return rendered;
  }
}

export default withRouter(withStore(Template));
