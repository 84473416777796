import React, { Component } from "react";

import Entry from "../../_core/models/Entry";
import Link from "../../_core/components/Link";
import SiteModifier from "../../_core/components/SiteModifier";
import Data from "../../_core/models/Data";

// Breakpoints
import * as Breakpoints from "../../_core/utils/Breakpoints";

import ProjectView from "../ProjectView";
import OpacityScreen from "../OpacityScreen";

import { withRouter } from "react-router-dom";

import Swiper from "../Swiper";

/*
  
  PROJECT is set in the <Router /> component based on the current entry template

*/

let HAS_HISTORY = false;

class Project extends Component {
  projectId = 0;
  isClosed = true;
  $scrollPane = false;

  constructor(props) {
    super(props);
    // Listen for history changes so that we can set a flag to confirm there is history for back buttons
    this.props.history.listen((location, action) => {
      if (action == "PUSH") HAS_HISTORY = true;
    });
  }

  close = () => {
    if (!this.isClosed) {
      this.isClosed = true;

      if (!HAS_HISTORY) {
        let backTo = "/";
        this.props.history.push(backTo);
      } else {
        this.props.history.goBack();
      }

      setTimeout(() => {
        this.$scrollPane.scrollX = 0;
        this.$scrollPane.scrollY = 0;
      }, 1000);
    }
  };

  componentDidMount = () => {};

  render() {
    let params = {
      slidesPerView: "auto",
      spaceBetween: 0,
      loop: false,
      grabCursor: true,
      centeredSlides: false,
      freeMode: true,
    };

    return (
      <Data>
        {({ OVERLAY, SHOW_OVERLAY }) => {
          if (SHOW_OVERLAY) {
            // Reset when we show a new project
            this.isClosed = false;
          }

          return (
            <div>
              {SHOW_OVERLAY ? (
                <SiteModifier modifier="overlay" auto />
              ) : (
                <SiteModifier demodifier="overlay" auto />
              )}

              {(() => {
                if (OVERLAY) {
                  // Show the related projects one after the next, and add this proejct to the front
                  let related = OVERLAY.related_projects || [];
                  let projects = [...related];
                  projects.unshift(OVERLAY.id);

                  return (
                    <div>
                      <div data-close>
                        <Link
                          className="Site-overlay-close"
                          back
                          fallback={"/"}
                        >
                          Close
                        </Link>
                      </div>
                      <Breakpoints.Mobile>
                        <div
                          data-scroll-pane
                          key={OVERLAY.id}
                          ref={(el) => (this.$scrollPane = el)}
                        >
                          <div className="Site-project-content">
                            <Entry
                              id={projects}
                              render={(project, ix) => {
                                return (
                                  <ProjectView
                                    key={`project_${ix}_${project.id}`}
                                    project={project}
                                  />
                                );
                              }}
                            />
                          </div>
                          <OpacityScreen
                            start={window.innerHeight}
                            reverse
                            onComplete={() => this.close()}
                          />
                        </div>
                      </Breakpoints.Mobile>
                      <Breakpoints.Default>
                        <div
                          data-scroll-pane
                          key={OVERLAY.id}
                          ref={(el) => (this.$scrollPane = el)}
                        >
                          <div className="Site-overlay-content">
                            <Entry
                              id={projects}
                              results={(projects, ix) => {
                                return (
                                  <Swiper params={params}>
                                    {projects.map((project, ix) => (
                                      <div
                                        key={`slide_${ix}`}
                                        className="swiper-slide"
                                      >
                                        <ProjectView
                                          key={`project_${ix}_${project.id}`}
                                          project={project}
                                        />
                                      </div>
                                    ))}
                                  </Swiper>
                                );
                              }}
                            />
                          </div>
                          <OpacityScreen
                            start={window.innerHeight}
                            reverse
                            onComplete={() => this.close()}
                          />
                        </div>
                      </Breakpoints.Default>
                    </div>
                  );
                }
              })()}
            </div>
          );
        }}
      </Data>
    );
  }
}

export default withRouter(Project);
