import React, { Component } from "react";

import Content from "../_core/components/Content";

class Index extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  /*

		RENDER

	*/

  render() {
    let { entry } = this.props;

    return (
      <div className="Template Template--basic">
        <div className="Template-head">
          <Content>
            <h1>{entry.title}</h1>
          </Content>
        </div>
        <div className="Template-body">
          <Content html={entry.content} />
        </div>
      </div>
    );
  }
}

export default Index;
