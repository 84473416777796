import React, { Component } from "react";
import "../_core/helpers/String"; // .decodeHTML prototype
import ScrollTo from "../_core/components/ScrollTo";
import Content from "../_core/components/Content";
import Data from "../_core/models/Data";
import Link from "../_core/components/Link";
import FadeIn from "../_core/components/FadeIn";

import Blocks from "../components/Blocks";
import Posts from "../components/Posts";
import CategoryNav from "../components/CategoryNav";

class NewsList extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  /*

		RENDER

	*/

  render() {
    let entry = this.props.entry;
    let blocks = entry.blocks || [];

    let content = [...blocks];

    return (
      <React.Fragment>
        <Blocks blocks={content} entry={entry} modifiers={["debug"]} />
        <div className="Template Template--journal">
          <div className="Template-content">
            <div className="Template-head">
              <CategoryNav />
            </div>

            <div className="Template-body">
              <Data>
                {({ CATEGORY_FILTER }) => {
                  let category = CATEGORY_FILTER ? CATEGORY_FILTER : null;

                  return (
                    <FadeIn key={"fade" + category}>
                      <Posts limit={18} key={category} category={category} />
                    </FadeIn>
                  );
                }}
              </Data>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default NewsList;
