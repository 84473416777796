import React, { Component } from "react";

import IsVisible from "../IsVisible";
import WatchSize from "../WatchSize";
import ImagePreloader from "../ImagePreloader";

class ImageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // Work out what src to load from props for a given width/height display size
  getSrcForSize = ({ width, height }) => {
    const fallback = this.props.large || this.props.small || this.props.src;
    const small = this.props.small ? this.props.small : this.props.src;
    const medium = this.props.medium ? this.props.medium : this.props.src;
    const large = this.props.large ? this.props.large : this.props.src;

    // Is the image being displaed at a rtio outside of its natural prortions? If so, recalculate therequired size

    // Use props.width props.height to tell component the *natural size* or *original size* of the image
    let scale = this.props.width / this.props.height / (width / height);

    // Work out the actual pixel area we are covering, allowing for aspect ratio and pixel density
    scale = isNaN(scale) || scale < 0.9 ? 1 : scale;

    // Get longest edge
    let longest = Math.max(width, height);

    // Create a singel value to measure against
    let w = scale ? longest * window.devicePixelRatio * scale : 3000; // No scale, load the largest version

    // The variable we will return once we calculate the best URL for the element size
    let src;

    // Default to large / original
    src = large;

    // Adjust src for smaller sizes
    if (w < 2000) src = medium;
    if (w < 1000) src = small;

    // Optional smaller sizes xs & thumb (assumes src,large,medium,small will be passed as props)
    if (w <= 600 && this.props.hasOwnProperty("xs")) src = this.props.xs;
    if (w <= 400 && this.props.hasOwnProperty("thumb")) src = this.props.thumb;

    // After all that, if no src then use fallback image (props.large > props.small > props.src)
    if (!src) src = fallback;

    // Override if SVG prop set
    if (this.props.svg) src = this.props.svg;

    return src;
  };

  render() {
    /*
    
    Layout style (ratio / fluid)

  */

    const ratioStyle = {
      paddingTop: 100 / this.props.ratio + "%",
    };
    const fluidStyle = {
      width: "100%",
      height: "100%",
      position: "absolute",
      top: 0,
      left: 0,
    };

    const style = this.props.fluid ? fluidStyle : ratioStyle;

    /*
      
      Classes / Modifiers from props

    */

    let className = this.props.className || "";

    if (this.props.fluid) className += " Image--fluid";
    if (this.state.visible) className += " Image--visible";
    if (this.state.loaded) className += " Image--loaded";
    if (this.props.orientation)
      className += " Image--orientation-" + this.props.orientation;
    if (this.props.lazy) className += " Image--lazy";

    let src = this.getSrcForSize({ width: 200, height: 200 });

    /*
      
      Extension from src

    */
    let parts = src.split(".");
    let ext = this.props.extension || parts[parts.length - 1];

    /*
      
      Not lazy - just return the media

    */

    if (!this.props.lazy) {
      let forceSrc = this.getSrcForSize({ width: 3000, height: 3000 });
      let media = null;
      if (this.props.useImg) {
        /* Return img tag if props.useImg */
        media = (
          <img
            src={src}
            className={`Image-media Image-media--uncached`}
            data-title={this.props.title || null}
            alt={
              this.props.alt ||
              this.props.title ||
              `Image from ${window.location.hostname}`
            }
          />
        );
      } else {
        /* Return div with background image by default */
        media = (
          <div
            className={`Image-media Image-media--uncached`}
            style={{ backgroundImage: "url(" + src + ")" }}
            title={this.props.title}
          />
        );
      }

      return (
        <div
          className={"Image Image--visible " + className}
          ref="image"
          style={style}
          data-lazy={this.props.lazy}
          data-debug={this.props.debug ? Math.random() : false}
          data-visible={true}
          data-extension={ext}
        >
          {media}
        </div>
      );
    }

    /*
      
      Render a lazy loaded image that starts upon visibility and adjusts the src based on element size

    */

    return (
      <IsVisible
        once={() => {
          /* Once visible, start loading the image */

          return (
            <div
              className={"Image Image--visible " + className}
              ref="image"
              style={style}
              data-lazy={this.props.lazy}
              data-debug={this.props.debug ? Math.random() : false}
              data-visible={this.state.visible}
              data-extension={ext}
            >
              <WatchSize interval={2000}>
                {({ size, sizeRefCallback }) => {
                  /* Watch the size of this child element */
                  /* Calculate the src to load for the current size (adjusted for aspect and pixel density) */

                  let sizedSrc = this.getSrcForSize({
                    width: size.width,
                    height: size.height,
                  });

                  /* Preload the src to load for this size */

                  return (
                    <ImagePreloader src={sizedSrc}>
                      {({ loaded, src, cached }) => {
                        if (this.props.useImg) {
                          /* Return img tag if props.useImg */
                          return (
                            <img
                              ref={(el) => sizeRefCallback(el)}
                              src={loaded ? src : null}
                              className={`Image-media Image-media--${
                                cached ? "cached" : "uncached"
                              }`}
                              data-title={this.props.title || null}
                              alt={
                                this.props.alt ||
                                this.props.title ||
                                `Image from ${window.location.hostname}`
                              }
                            />
                          );
                        } else {
                          /* Return div with background image by default */
                          return (
                            <div
                              data-size={`${size.width}x${size.height}`}
                              className={`Image-media Image-media--${
                                cached ? "cached" : "uncached"
                              }`}
                              style={
                                loaded
                                  ? { backgroundImage: "url(" + src + ")" }
                                  : null
                              }
                              title={this.props.title}
                              ref={(el) => sizeRefCallback(el)}
                            >
                              {/*Best image for {size.width} x {size.height}: {sizedSrc}*/}
                            </div>
                          );
                        }
                      }}
                    </ImagePreloader>
                  );
                }}
              </WatchSize>

              {(() => {
                /* If props.raw, add an invisible img tag outside the lazyloader so the markup always renders an img (SEO) */

                if (this.props.raw) {
                  return (
                    <img
                      src={src}
                      className="Image-raw"
                      data-title={this.props.title || null}
                      alt={this.props.alt || null}
                      style={{
                        opacity: 0,
                        display: "block",
                        zIndex: 1,
                        ...fluidStyle,
                      }}
                    />
                  );
                }
              })()}
            </div>
          );
        }}
        fallback={() => {
          /* If not visible, load fallback */
          return (
            <div
              className={"Image Image--loading " + className}
              data-visible={this.state.visible}
              ref="image"
              data-extension={ext}
              style={style}
            >
              <div className="Image-media" title={this.props.title} />
            </div>
          );
        }}
      />
    );
  }
}

export default ImageComponent;
