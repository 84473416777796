import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { withStore } from "../../_core/utils/Store";

window.SCROLLING_BUSY = false;

const TweenLite = window.TweenLite;
const Expo = window.Expo;

/*

ScrollManager - scroll to top

*/

let ENTRIES = [];

let TL = null; // Animation instance to kill if mousewheel or touch move
let LAST_SCROLL = 0;

class ScrollManager extends Component {
  animating = 0;
  stopListening;

  cur = "";

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let _this = this;

    let checkScroll = () => {
      ENTRIES = ENTRIES.length ? ENTRIES : this.props.store.get("entries");

      let found = ENTRIES.find(
        (entry) => entry.uri == window.location.pathname
      );
      let SCROLL_TO_TOP = true;

      if (found) {
        // Don't scroll page or basic templates because those will load into the panel
        if (["panel", "overlay"].indexOf(found.template) != -1) {
          SCROLL_TO_TOP = false;
        }

        // Don't scroll posts or projects because they load into a layer
        if (["post"].indexOf(found.type) != -1) {
          SCROLL_TO_TOP = false;
        }
      }

      if (window.location.hash) {
        SCROLL_TO_TOP = false;

        if (!window.location.hash.match("/")) {
          let target = document.querySelector(window.location.hash);

          if (
            target &&
            (target.getAttribute("href") || target.getAttribute("data-href"))
          ) {
            // Hash matches a link id with an href. Trigger a click on that element
            target.click();
          } else {
            setTimeout(function () {
              TL = TweenLite.to(window, 1.2, {
                scrollTo: { y: window.location.hash, autoKill: false },
                ease: Expo.easeInOut,
                onComplete: function () {
                  window.SCROLLING_BUSY = 0;
                  _this.animating = 0;
                },
              });
            }, 100);
          }
        }
      }

      // Scroll the window
      if (SCROLL_TO_TOP) {
        LAST_SCROLL = window.scrollY;
        window.scrollTo(0, 0);
      }
    };

    this.backListener = this.props.history.listen((location) => {
      //console.log(this.props.history.action);

      if (this.props.history.action === "POP") {
        //console.log('ignoring');

        // Return to last stored position
        if (LAST_SCROLL) {
          setTimeout(function () {
            window.scrollTo(0, LAST_SCROLL);
            LAST_SCROLL = 0;
          }, 0);
        }
      } else {
        checkScroll();
      }
    });

    document.addEventListener("click", this.kill, false);
    // nonstandard: Chrome, IE, Opera, Safari
    document.addEventListener("mousewheel", this.kill, false);
    // nonstandard: Firefox
    document.addEventListener("DOMMouseScroll", this.kill, false);

    document.addEventListener("touchstart", this.kill, false);
    document.addEventListener("touchmove", this.kill, false);
    document.addEventListener("touchend", this.kill, false);

    // Run first check
    checkScroll();
  }

  kill = () => {
    if (TL) {
      // Kill tweenlite anim
      TL.kill();
    }
  };

  componentWillUnmount() {
    let el = this.refs.el;

    el.removeEventListener("click", this.kill);
    // nonstandard: Chrome, IE, Opera, Safari
    el.removeEventListener("mousewheel", this.kill);
    // nonstandard: Firefox
    el.removeEventListener("DOMMouseScroll", this.kill);

    el.removeEventListener("touchstart", this.kill);
    el.removeEventListener("touchmove", this.kill);
    el.removeEventListener("touchend", this.kill);

    this.backListener();
  }

  auto = () => {
    // Look for a matching anchor
    let hash = window.location.hash || "";

    let found = this.anchor();

    let props = { ...this.props };

    if (found) {
      props.to = hash;
    } else {
      props.pos = 0;
    }

    // Animate if matching element or no match and no hash
    if (found && this.props.minDistance) {
      let dist = Math.abs(found.getBoundingClientRect().top); // Not absolute. Top s relative to scroll, so can be used as distance)
      if (dist <= this.props.minDistance) return;
    }
    if (found || (!found && !hash)) this.animate(props);
  };

  animate = (props) => {
    const _this = this;

    if (window.SCROLLING_BUSY) return;

    if (props.to == "#") return;

    //if(_this.animating) return;

    let scroller = props.scroller
      ? document.querySelector(props.scroller)
      : window;
    let duration = props.hasOwnProperty("duration") ? props.duration : 1.5;

    let offset = parseInt(props.offset) || 0;
    if (typeof props.offset == "function") offset = props.offset;
    let to = props.to || props.pos;

    if (offset && typeof to == "string" && to.match("#")) {
      let el = document.querySelector(props.to);
      let pos = el.offsetTop;
      let offsetAmt = typeof offset == "function" ? offset(el) : offset;

      to = pos - offsetAmt;
    } else if (offset && typeof to == "number") {
      let offsetAmt = typeof offset == "function" ? offset(null) : offset;
      to -= offsetAmt;
    }

    window.SCROLLING_BUSY = 1;
    _this.animating = 1;

    setTimeout(function () {
      TweenLite.to(scroller, duration, {
        scrollTo: { y: to, autoKill: false },
        ease: Expo.easeInOut,
        onComplete: function () {
          window.SCROLLING_BUSY = 0;
          _this.animating = 0;
        },
      });
    }, 100);
  };

  render() {
    return <div ref="el" />;
  }
}

export default withStore(withRouter(ScrollManager));
