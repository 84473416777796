import React, { Component } from "react";
//import {TimelineMax,TweenLite,Linear,Sine,Expo,Power0,Power4} from 'gsap';
import { withRouter } from "react-router-dom";

/*

Add this to index.html
<script src="//cdnjs.cloudflare.com/ajax/libs/gsap/2.0.2/TweenMax.min.js"></script>
<script src="//cdnjs.cloudflare.com/ajax/libs/gsap/2.0.2/easing/EasePack.min.js"></script>
<script src="//cdnjs.cloudflare.com/ajax/libs/gsap/2.0.2/plugins/ScrollToPlugin.min.js"></script>    

*/

const TweenLite = window.TweenLite;
const Expo = window.Expo;
const Sine = window.Sine;

/*

FadeIn - fade in when the page changes

*/

class FadeIn extends Component {
  animating = 0;

  componentDidMount() {
    this.fade();
  }

  componentWillReceiveProps(nextProps) {
    const currentPage = this.props.location.pathname;
    const nextPage = nextProps.location.pathname;

    if (this.props.key == nextProps.key) return;
    if (currentPage !== nextPage || this.props.key != nextProps.key) {
      this.fade();
    }
  }

  fade = () => {
    const _this = this;

    if (_this.animating || !this.refs.el) return;

    _this.animating = 1;

    let duration = this.props.duration || 1;
    let delay = this.props.delay || 0;

    TweenLite.from(this.refs.el, duration, {
      opacity: 0,
      delay: delay,
      ease: Sine.easeInOut,
      onComplete: function () {
        _this.animating = 0;
      },
    });
  };

  render() {
    return <div ref="el">{this.props.children}</div>;
  }
}

export default withRouter(FadeIn);
