import React, { Component } from "react";

import Link from "../../_core/components/Link";
import SiteModifier from "../../_core/components/SiteModifier";
import FadeIn from "../../_core/components/FadeIn";
import Template from "../../_core/controllers/Template";
import Data from "../../_core/models/Data";

import Home from "../../templates/index";

class Main extends Component {
  render() {
    return (
      <Data>
        {({ HOME, MAIN }) => {
          return (
            <FadeIn duration={1} key={"main_" + MAIN.id}>
              <Template entry={MAIN} />
            </FadeIn>
          );
        }}
      </Data>
    );
  }
}

export default Main;
