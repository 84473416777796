import React, { Component } from "react";

import { withRouter } from "react-router-dom";

import Link from "../../_core/components/Link";
import Content from "../../_core/components/Content";
import SiteModifier from "../../_core/components/SiteModifier";
import Data from "../../_core/models/Data";
import Blocks from "../../components/Blocks";
import FadeIn from "../../_core/components/FadeIn";

/*
  
  PANEL is set in the <Router /> component based on the current entry template

*/

let PAGEVIEWS = 0;

class Panel extends Component {
  componentDidMount() {
    let _this = this;

    this.listener = this.props.history.listen((location) => {
      //console.log(this.props.history.action);

      if (this.props.history.action === "POP") {
      } else {
        // Increase pageviews
        PAGEVIEWS++;
      }
    });
  }

  componentWillUnmount() {
    // Kill listener
    this.listener();
  }

  render() {
    return (
      <Data>
        {({ PANEL, SHOW_PANEL }) => {
          return (
            <div>
              {SHOW_PANEL ? (
                <SiteModifier
                  modifier="panel"
                  auto
                  key={PANEL.id + "_" + PAGEVIEWS}
                />
              ) : (
                <SiteModifier demodifier="panel" auto key={PANEL.id} />
              )}

              {(() => {
                if (PANEL) {
                  let blocks = PANEL.blocks || [];

                  return (
                    <div>
                      <div demodifier="panel">
                        <div data-close>
                          <Link
                            className="Site-panel-close"
                            back
                            fallback={"/"}
                          >
                            Close
                          </Link>
                        </div>
                      </div>

                      <div data-scroll-pane key={PANEL.id}>
                        <FadeIn duration={0.6} key={PANEL.id}>
                          <Blocks
                            blocks={[...blocks]}
                            entry={PANEL}
                            modifiers={["debug"]}
                          />
                        </FadeIn>
                      </div>
                    </div>
                  );
                }
              })()}
            </div>
          );
        }}
      </Data>
    );
  }
}

export default withRouter(Panel);
