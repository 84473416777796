import React, { Component } from "react";

// Core
import Link from "../_core/components/Link";
import Data from "../_core/models/Data";
import ScrollTo from "../_core/components/ScrollTo";
import Content from "../_core/components/Content";
import ScrollModifier from "../_core/components/ScrollModifier";
import SiteModifier from "../_core/components/SiteModifier";

// Components
import Burger from "../components/Burger";
import Nav from "../components/Nav";
import IconNav from "../components/IconNav";
import Logo from "../components/Logo";
import Main from "../components/Main";
import Article from "../components/Article";
import Panel from "../components/Panel";
import Overlay from "../components/Overlay";
import Footer from "../components/Footer";
import ContactForm from "../components/ContactForm";
import ScrollManager from "../components/ScrollManager";
import Meta from "../components/Meta";
import Popup from "../components/Popup";

class Layout extends Component {
  constructor(props) {
    super(props);
  }

  /*

		RENDER

	*/

  render() {
    return (
      <div data-layout className="Site-page">
        {/*
					Update metadata in <head>
				*/}
        <Meta />

        {/*
					Add ready modifier to close intro
				*/}
        <SiteModifier modifier={"open"} auto={true} delay={500} />

        {/*
					Scroll direction modifier
				*/}
        <ScrollModifier reverse={true} />

        {/*
					Scroll manager - listen to page changes and decide whether to jump to top
				*/}
        <ScrollManager />

        {/*
					Logo - back to home
				*/}
        <div className="Site-logo">
          <Link to={"/"}>
            <Logo />
          </Link>
        </div>

        {/*
					Burger - show and hide nav
				*/}
        <div className="Site-burger">
          <Burger />
        </div>

        {/*
					Article - journal reader
				*/}
        <div className="Site-article">
          <Article />
        </div>

        {/*
					Panel - panel template
				*/}
        <div className="Site-panel">
          <Panel />
        </div>

        {/*
					Overlay - overlay template
				*/}
        <div className="Site-overlay">
          <Overlay />
        </div>

        {/*
					Menu - main navigation
				*/}
        <div className="Site-menu">
          <Nav label="main" modifier="main" />
        </div>

        {/*
					Main content - layer controlling scroll bad background content
				*/}
        <div className="Site-main">
          <Main />
        </div>

        {/*
					Header nav - main navigation
				*/}
        <div className="Site-header-nav">
          <IconNav label="social" />
          <Nav label="header" modifier="header" />
        </div>

        {/*
					Shortcuts nav - quick buttons
				*/}
        <div className="Site-shortcuts-nav">
          <Nav label="shortcuts" modifier="shortcuts" />
        </div>

        {/*
					Footer - nav and contact
				*/}

        <div className="Site-footer">
          <div className="Site-footer-contact">
            <div className="Site-footer-content">
              <Footer />
            </div>

            <div className="Site-footer-foot">
              <div className="Site-footer-nav">
                <Nav label="footer" modifier="footer" />
              </div>

              <div className="Site-footer-credit">
                <Content modifier={"credit"}>
                  <p>
                    Design by{" "}
                    <a href="https://e-i-b.com">Everything In Between</a>
                  </p>
                </Content>
              </div>
            </div>
          </div>
        </div>

        {/*
					Message/popup
				*/}
        <Popup />
      </div>
    );
  }
}

export default Layout;
