import React, { Component } from "react";
import { withStore } from "../../utils/Store";

import "../../helpers/Array";

/* e.g

// Only update this view when foo changes
<Data observe={['foo']}>
  {({foo})=>{
    return <div>{JSON.stringify(foo)}}</div>
  }}
</Data>

// Always update this view when any parent re-renders
<Data>
  {({foo})=>{
    return <div>{JSON.stringify(foo)}}</div>
  }}
</Data>

*/

class Data extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.checkSet();
  }

  componentDidUpdate(nextProps) {
    this.checkSet(nextProps);
  }

  checkSet = (nextProps) => {
    let _this = this;
    if (this.props.set) {
      if (!nextProps) {
        _this.setData(this.props.set);
      } else {
        let shouldSet = false;
        let toStore = {};
        Object.keys(this.props.set).forEach((k) => {
          if (this.props.set[k] != nextProps.set[k]) {
            toStore[k] = nextProps.set[k];
          }
        });

        _this.setData(toStore);
      }
    }
  };

  setData = (data) => {
    let _this = this;
    let toStore = Object.keys(data);

    if (toStore.length) {
      //console.log('TO STORE',data);

      toStore.forEach((k) => {
        _this.props.store.store(k, this.props.set[k]);
      });

      _this.props.store.publish();
    }
  };

  componentWillUnmount() {}

  shouldComponentUpdate = (nextProps) => {
    if (!nextProps.dataChanged.length) return false;

    if (!this.props.observe && !this.props.ignore) return true;

    let hasObserverChanged = false;
    let hasIgnoreChanged = false;

    if (this.props.observe) {
      hasObserverChanged = this.props.observe.contains(nextProps.dataChanged);
      if (hasObserverChanged) {
        // Strict observe - only update if values have changed
        if (this.props.strict) {
          let hasChanged = 0;
          let changed = this.props.observe.find((k) => {
            return (
              JSON.stringify(this.props.data[k]) !=
              JSON.stringify(nextProps.data[k])
            );
          });
          return changed;
          // Non-strict (default), update if the key is in the array regardless of value
        } else {
          return true;
        }
      }
    }

    if (this.props.ignore) {
      hasIgnoreChanged = this.props.ignore.contains(nextProps.dataChanged);
      // Something else changed, not an ignore value...let it through
      if (!hasIgnoreChanged) return true;
    }

    return false;
  };

  /*

		RENDER

	*/

  render() {
    let data = this.props.data;
    //console.log('DATA',data);

    if (!data) return null;

    // Return NULL if required data values not present
    if (this.props.require) {
      let requiredMet = true;
      this.props.require.forEach((k) => {
        if (!data.hasOwnProperty(k)) requiredMet = false;
      });

      if (!requiredMet) return null;
    }
    let rendered = null;
    try {
      rendered =
        typeof this.props.children == "function"
          ? this.props.children(data)
          : this.props.children;
    } catch (exp) {
      console.error(exp);
    }

    return rendered ? rendered : null;
  }
}

export default withStore(Data);
